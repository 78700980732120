/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DriverScatterPlotWidgetResponse } from './DriverScatterPlotWidgetResponse';
import type { DriverTableWidgetResponse } from './DriverTableWidgetResponse';
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { TextWidgetResponse } from './TextWidgetResponse';
export type DriverTableInsightElementResponse = {
  id: string;
  kind?: 'DriverTableInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  driver_table_analysis: DriverTableWidgetResponse;
  driver_summary: TextWidgetResponse;
  driver_scatter_plot_analysis: DriverScatterPlotWidgetResponse;
  vertical_chart?: boolean;
  sentiment_toggle?: boolean;
  visible_right: DriverTableInsightElementResponse.visible_right;
};
export namespace DriverTableInsightElementResponse {
  export enum visible_right {
    DRIVER_SUMMARY = 'driver_summary',
    DRIVER_SCATTER_PLOT_ANALYSIS = 'driver_scatter_plot_analysis',
  }
}

