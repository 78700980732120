import { ApiError, AuxiliaryFieldUIResponse, ProjectsService, TextToAnalyzeFieldUIResponse } from '@/api'
import { getErrorMsg } from '@/api/helpers'
import { keepPreviousData, useMutation, useQuery } from '@tanstack/vue-query'
import { queryClient } from '@/plugins/vue-query'
import { toValue } from 'vue'
import type { MaybeRef } from 'vue'
import type {
  ObjectPermissionListConfigResponse_ProjectUserPermission_,
  PaginatedObjectPermissionListResponse_ProjectUserPermission_,
  PaginatedProjectListUIResponse,
  ProjectCodingBulkAssignUIResponse,
  ProjectCodingRowTextUIResponse,
  ProjectColumnSampleDataUIResponse,
  ProjectCreateUIResponse,
  ProjectDetailPatchUIResponse,
  ProjectDetailUIResponse,
  ProjectListUIConfigResponse,
  RowDetailUIResponse,
  RowListUIResponse,
  TextToAnalyzeStatsResponse,
  TopicAssignmentWorkerObserverResponse,
  TopicUIResponse,
} from '@/api'

// All method keys in ProjectsService
type TProjectsServiceMethodKeys = ClassMethodKeys<typeof ProjectsService>
// Parameter types extractor
export type ArgTypes<K extends TProjectsServiceMethodKeys> = ClassMethodParamType<typeof ProjectsService, K>

/***********/
/* QUERIES */
/***********/

export function useProjectListQuery(
  params: ArgTypes<'projectList'> = {},
  options: TCustomUseQueryOptions<PaginatedProjectListUIResponse> = {}
) {
  return useQuery({
    queryKey: ['projects', params],
    queryFn: () => ProjectsService.projectList(params),
    placeholderData: keepPreviousData,
    // any default option should come before this line to keep it overridable
    ...options,
  })
}

export function useProjectQuery(
  params: ArgTypes<'project'>,
  options: TCustomUseQueryOptions<ProjectDetailUIResponse> = {}
) {
  return useQuery({
    queryKey: ['projects', params.id],
    queryFn: () => ProjectsService.project(params),
    refetchOnMount: false,
    // any default option should come before this line to keep it overridable
    ...options,
  })
}

export function useProjectColumnSampleDataQuery(
  params: ArgTypes<'projectColumnSampleData'>,
  options: TCustomUseQueryOptions<ProjectColumnSampleDataUIResponse> = {}
) {
  return useQuery({
    queryKey: ['project-column-sample-data', params.id],
    queryFn: () => ProjectsService.projectColumnSampleData(params),
    // any default option should come before this line to keep it overridable
    ...options,
  })
}

export function useProjectPermissionListQuery(
  projectId: string,
  params: MaybeRef<Omit<ArgTypes<'projectPermissionList'>, 'projectId'>> = {},
  options: TCustomUseQueryOptions<PaginatedObjectPermissionListResponse_ProjectUserPermission_> = {}
) {
  return useQuery({
    queryKey: ['project-permission-list', projectId, toValue(params)],
    queryFn: () => ProjectsService.projectPermissionList({ ...toValue(params), projectId }),
    ...options,
  })
}

export function useProjectPermissionListConfigQuery<TData = ObjectPermissionListConfigResponse_ProjectUserPermission_>(
  params: ArgTypes<'projectPermissionListConfig'>,
  options: TCustomUseQueryOptions<ObjectPermissionListConfigResponse_ProjectUserPermission_, TData> = {}
) {
  return useQuery({
    queryKey: ['project-permission-list-config'],
    queryFn: () => ProjectsService.projectPermissionListConfig(params),
    ...options,
  })
}

// TODO: use backend api to get the list of columns for a report type when it's available
// for now, we are using frontend logic hence types are defined here, later they will come from backend
type TRequiredColumnsByType = Record<string, Array<{ column_type: TMergedColumnType['type'] }>>
type TAvailableColumnsByType = Record<TMergedColumnType['type'], Array<TMergedColumnType>>
export type TProjectColumnsForReportCreateQueryResponse = {
  required_columns_by_type: TRequiredColumnsByType
  available_columns: TAvailableColumnsByType
}

export function useProjectColumnsForReportCreateQuery(
  params: ArgTypes<'project'>,
  options: TCustomUseQueryOptions<ProjectDetailUIResponse, TProjectColumnsForReportCreateQueryResponse> = {}
) {
  return useQuery({
    queryKey: ['project-columns-for-report-create', params.id],
    queryFn: () => ProjectsService.project(params),
    ...options,
    select: (data) => {
      const columns = data?.columns || []

      type TProjectColumnsForReport = {
        required_columns_by_type: Partial<
          Record<TReportType, Array<{ column_type: AuxiliaryFieldUIResponse.type | TextToAnalyzeFieldUIResponse.type }>>
        >
        available_columns: Record<
          AuxiliaryFieldUIResponse.type | TextToAnalyzeFieldUIResponse.type,
          Array<TextToAnalyzeFieldUIResponse | AuxiliaryFieldUIResponse>
        >
      }

      const result: TProjectColumnsForReport = {
        // for now only nps is defined
        required_columns_by_type: {
          'blank-over-time': [{ column_type: AuxiliaryFieldUIResponse.type.DATE }],
          nps: [
            { column_type: AuxiliaryFieldUIResponse.type.NUMERICAL },
            { column_type: TextToAnalyzeFieldUIResponse.type.TEXT_TO_ANALYZE },
          ],
          'nps-over-time': [
            { column_type: AuxiliaryFieldUIResponse.type.NUMERICAL },
            { column_type: TextToAnalyzeFieldUIResponse.type.TEXT_TO_ANALYZE },
            { column_type: AuxiliaryFieldUIResponse.type.DATE },
          ],
          review: [
            { column_type: AuxiliaryFieldUIResponse.type.NUMERICAL },
            { column_type: TextToAnalyzeFieldUIResponse.type.TEXT_TO_ANALYZE },
          ],
          'review-over-time': [
            { column_type: AuxiliaryFieldUIResponse.type.NUMERICAL },
            { column_type: TextToAnalyzeFieldUIResponse.type.TEXT_TO_ANALYZE },
            { column_type: AuxiliaryFieldUIResponse.type.DATE },
          ],
        },
        available_columns: {
          [AuxiliaryFieldUIResponse.type.NUMERICAL]: columns.filter(
            (c) => c.type === AuxiliaryFieldUIResponse.type.NUMERICAL
          ),
          [AuxiliaryFieldUIResponse.type.DATE]: columns.filter((c) => c.type === AuxiliaryFieldUIResponse.type.DATE),
          [AuxiliaryFieldUIResponse.type.TEXT]: columns.filter((c) => c.type === AuxiliaryFieldUIResponse.type.TEXT),
          [AuxiliaryFieldUIResponse.type.BOOLEAN]: columns.filter(
            (c) => c.type === AuxiliaryFieldUIResponse.type.BOOLEAN
          ),
          [AuxiliaryFieldUIResponse.type.ANY]: columns.filter((c) => c.type === AuxiliaryFieldUIResponse.type.ANY),
          [TextToAnalyzeFieldUIResponse.type.TEXT_TO_ANALYZE]: columns.filter(
            (c) => c.type === TextToAnalyzeFieldUIResponse.type.TEXT_TO_ANALYZE
          ),
        },
      }

      return result
    },
  })
}

export function useProjectListConfigQuery(options: TCustomUseQueryOptions<ProjectListUIConfigResponse> = {}) {
  return useQuery({ queryKey: ['projects-config'], queryFn: () => ProjectsService.projectListConfig(), ...options })
}

export function useProjectRowListQuery(
  id: string,
  params: Omit<ArgTypes<'projectRowList'>, 'id'> = {},
  options: TCustomUseQueryOptions<RowListUIResponse> = {}
) {
  return useQuery({
    queryKey: ['project-row-list', id, params],
    queryFn: () => ProjectsService.projectRowList({ id, ...params }),
    placeholderData: keepPreviousData,
    ...options,
  })
}

export function useProjectQuestionStatsQuery(
  params: ArgTypes<'projectQuestionStats'>,
  options: TCustomUseQueryOptions<TextToAnalyzeStatsResponse> = {}
) {
  return useQuery({
    queryKey: ['project-question-stats', [params.id, params.ref]],
    queryFn: () => ProjectsService.projectQuestionStats(params),
    // any default option should come before this line to keep it overridable
    ...options,
  })
}

export function useProjectWorkerObserver(
  params: ArgTypes<'topicAssignmentWorkerObserver'>,
  options: TCustomUseQueryOptions<TopicAssignmentWorkerObserverResponse> = {}
) {
  return useQuery({
    queryKey: ['project-question-stats', params],
    queryFn: () => ProjectsService.topicAssignmentWorkerObserver(params),
    refetchInterval: 5000,
    // any default option should come before this line to keep it overridable
    ...options,
  })
}

/*************/
/* MUTATIONS */
/*************/

type TProjectCreateMutationOptions = TCustomUseMutationOptions<ProjectCreateUIResponse, ArgTypes<'projectCreate'>>

export function useProjectCreateMutation(options: TProjectCreateMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.projectCreate(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
    onSuccess: async () => {
      return await queryClient.invalidateQueries({ queryKey: ['projects'] })
    },
  })
}

type TProjectUpdateMutationOptions = TCustomUseMutationOptions<ProjectDetailPatchUIResponse, ArgTypes<'projectUpdate'>>
export function useProjectUpdateMutation(options: TProjectUpdateMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.projectUpdate(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
    // onSuccess should not be overwritten
    onSuccess: async (_, params) => {
      return await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['projects'] }),
        queryClient.invalidateQueries({ queryKey: ['projects-config'] }),
        queryClient.invalidateQueries({ queryKey: ['project-row-list', params.id] }),
        queryClient.invalidateQueries({ queryKey: ['project', params.id] }),
      ])
    },
  })
}

type TProjectDeleteMutationOptions = TCustomUseMutationOptions<void, ArgTypes<'projectDelete'>>
export function useProjectDeleteMutation(options: TProjectDeleteMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.projectDelete(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
    onSuccess: async () => {
      return await queryClient.invalidateQueries({ queryKey: ['projects'] })
    },
  })
}

type TTopicUpdateMutationOptions = TCustomUseMutationOptions<Array<TopicUIResponse>, ArgTypes<'topicUpdate'>>
export function useTopicUpdateMutation(projectId?: string, options: TTopicUpdateMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.topicUpdate(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    // if any topics are merged or updated, project query must be invalidated
    onSuccess: () => {
      if (projectId) queryClient.invalidateQueries({ queryKey: ['project', projectId] })
      else queryClient.invalidateQueries({ queryKey: ['projects'] })
    },
    ...options,
  })
}

type TTopicsMergeMutationOptions = TCustomUseMutationOptions<Array<TopicUIResponse>, ArgTypes<'topicsMerge'>>
export function useTopicsMergeMutation(projectId: string, options: TTopicsMergeMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.topicsMerge(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
    // onSuccess should not be overwritten
    // if any topics are merged or updated, project query must be invalidated
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['project', projectId] })
    },
  })
}

type TCodeProjectRowMutationOptions = TCustomUseMutationOptions<
  ProjectCodingRowTextUIResponse,
  ArgTypes<'codeProjectRow'>
>
export function useCodeProjectRowMutation(options: TCodeProjectRowMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.codeProjectRow(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
  })
}

type TBulkCodeProjectRowMutationOptions = TCustomUseMutationOptions<
  ProjectCodingBulkAssignUIResponse,
  ArgTypes<'bulkCodeProjectRowList'>
>
export function useBulkCodeProjectRowListMutation(options: TBulkCodeProjectRowMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.bulkCodeProjectRowList(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
  })
}

type TDeleteProjectCodingRowsMutationOptions = TCustomUseMutationOptions<
  void,
  ArgTypes<'projectCodingRowDelete'>,
  { previousProject: ProjectDetailUIResponse | undefined }
>
export function useDeleteProjectCodingRowsMutation(options: TDeleteProjectCodingRowsMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.projectCodingRowDelete(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({ queryKey: ['project-row-list', params.id] })
    },
    ...options,
  })
}

type TProjectRowUpdateMutationOptions = TCustomUseMutationOptions<RowDetailUIResponse, ArgTypes<'projectRowUpdate'>>
export function useProjectRowUpdateMutation(options: TProjectRowUpdateMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.projectRowUpdate(mutationParams),
    ...options,
    onSuccess: async (_, params) => {
      // always invalidate the queries to make sure data is up-to date after mutation
      return await queryClient.invalidateQueries({ queryKey: ['project-row-list', params.projectId] })
    },
    onError: (err: ApiError) => {
      window.$message.error(getErrorMsg(err))
    },
  })
}

type TMergeColumnsMutationOptions = TCustomUseMutationOptions<unknown, ArgTypes<'projectMergeColumns'>>
export function useMergeColumnsMutation(options: TMergeColumnsMutationOptions = {}) {
  return useMutation({
    // eslint-disable-next-line max-len
    mutationFn: (mutationParams) => ProjectsService.projectMergeColumns(mutationParams),
    ...options,
    // onSuccess should not be overwritten
    onSuccess: async (_, params) => {
      return await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['projects'] }),
        queryClient.invalidateQueries({ queryKey: ['projects-config'] }),
        queryClient.invalidateQueries({ queryKey: ['project-row-list', params.project] }),
      ])
    },
    onError: (err: ApiError) => {
      window.$message.error(getErrorMsg(err))
    },
  })
}

type TProjectPermissionsUpdateMutation = TCustomUseMutationOptions<unknown, ArgTypes<'projectPermissionsUpdate'>>
export function useProjectPermissionsUpdateMutation(options: TProjectPermissionsUpdateMutation = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.projectPermissionsUpdate(mutationParams),
    ...options,
    onSuccess: async () => {
      return await queryClient.invalidateQueries({ queryKey: ['project-permission-list'] })
    },
    onError: (err: ApiError) => {
      window.$message.error(getErrorMsg(err))
    },
  })
}

type TProjectOwnershipTransferMutation = TCustomUseMutationOptions<unknown, ArgTypes<'projectOwnershipTransfer'>>
export function useProjectOwnershipTransferMutation(options: TProjectOwnershipTransferMutation = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsService.projectOwnershipTransfer(mutationParams),
    ...options,
    onSuccess: async () => {
      return await queryClient.invalidateQueries({ queryKey: ['project-permission-list'] })
    },
    onError: (err: ApiError) => {
      window.$message.error(getErrorMsg(err))
    },
  })
}
