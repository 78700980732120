import { MarketingService } from '@/api'
import { addDays, isAfter } from 'date-fns'
import { computed, onMounted, ref } from 'vue'
import { defineStore } from 'pinia'
import { getActiveFeedbackSurvey, getActiveG2ReviewSurvey } from '@/plugins/posthog'
import { useAuthStore } from '@/store'
import { useCustomFetch } from '@/api/helpers'
import { useEventSubscription } from '@/composables/useEventBus'
import type { Survey } from 'posthog-js'

const G2_SURVEY_INTERACTION_KEY = 'g2_survey_interaction'

export const useSurveyStore = defineStore('survey', () => {
  const feedbackSurvey = ref<Survey>()
  const g2ReviewSurvey = ref<Survey>()
  const activeSurvey = ref<Survey>()
  const authStore = useAuthStore()

  const { fetch: initializeG2Survey, data: g2ReviewToken } = useCustomFetch(MarketingService.g2ReviewTokenGet)

  const g2ReviewFormUrl = computed(() => {
    const surveyUrl = import.meta.env.CAPP_G2_REVIEW_SURVEY_URL

    if (!g2ReviewToken.value || !authStore.user?.email) {
      return undefined
    }

    return `${surveyUrl}/users/login.embed?state=${g2ReviewToken.value.state}&email=${authStore.user?.email}`
  })

  const setFeedbackSurvey = (survey?: Survey) => {
    feedbackSurvey.value = survey
  }

  const setG2ReviewSurvey = (survey?: Survey) => {
    g2ReviewSurvey.value = survey
  }

  const setActiveSurvey = (survey?: Survey) => {
    activeSurvey.value = survey
  }

  const hasUserInteractedWithG2Survey = (): boolean => {
    const lastInteraction = localStorage.getItem(G2_SURVEY_INTERACTION_KEY)

    if (!lastInteraction) {
      return false
    }

    return isAfter(new Date(), new Date(lastInteraction)) || lastInteraction === 'true'
  }

  const postPoneG2SurveyForFiveDays = () => {
    localStorage.setItem(G2_SURVEY_INTERACTION_KEY, addDays(new Date(), 5).toISOString())
  }

  const interactedWithG2Survey = () => {
    localStorage.setItem(G2_SURVEY_INTERACTION_KEY, 'true')
  }

  onMounted(() => {
    getActiveFeedbackSurvey((survey) => {
      setFeedbackSurvey(survey)
    })

    getActiveG2ReviewSurvey((survey) => {
      setG2ReviewSurvey(survey)
    })
  })

  useEventSubscription('posthog-user-identified', () => {
    getActiveFeedbackSurvey((survey) => {
      setFeedbackSurvey(survey)
    })

    getActiveG2ReviewSurvey((survey) => {
      setG2ReviewSurvey(survey)
      initializeG2Survey()
    })
  })

  return {
    feedbackSurvey,
    g2ReviewSurvey,
    activeSurvey,
    g2ReviewToken,
    g2ReviewFormUrl,
    initializeG2Survey,
    setActiveSurvey,
    hasUserInteractedWithG2Survey,
    interactedWithG2Survey,
    postPoneG2SurveyForFiveDays,
  }
})
