/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DriverTableInsightElementUpdateRequest = {
  kind?: 'DriverTableInsight';
  order_id?: number;
  visible_right?: DriverTableInsightElementUpdateRequest.visible_right;
};
export namespace DriverTableInsightElementUpdateRequest {
  export enum visible_right {
    DRIVER_SUMMARY = 'driver_summary',
    DRIVER_SCATTER_PLOT_ANALYSIS = 'driver_scatter_plot_analysis',
  }
}

