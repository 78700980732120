/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum WidgetKind {
  TTA_COLUMN_ROWS = 'TtaColumnRows',
  TEXT = 'Text',
  MARKDOWN_TEXT = 'MarkdownText',
  REPORT_SUMMARY_WIDGET = 'ReportSummaryWidget',
  NPSSCORE_GAUGE_CHART = 'NPSScore_GaugeChart',
  NPSSCORE_PIE_CHART = 'NPSScore_PieChart',
  NPSSCORE_SEGMENTED_BAR_CHART = 'NPSScore_SegmentedBarChart',
  NPSSCORE_LINE_CHART = 'NPSScore_LineChart',
  RATING_BREAKDOWN = 'RatingBreakdown',
  RATING_SEGMENTED_BAR = 'RatingSegmentedBar',
  RATING_OVER_TIME_LINE = 'RatingOverTimeLine',
  TOPICS_OVERVIEW_TREEMAP = 'TopicsOverview_Treemap',
  TOPICS_OVERVIEW_SEGMENTED_BAR_CHART = 'TopicsOverview_SegmentedBarChart',
  TOPICS_OVERVIEW_TOPICS_SUMMARY = 'TopicsOverview_TopicsSummary',
  TOPICS_OVERVIEW_SEGMENTED_BUCKET_BAR_CHART = 'TopicsOverview_SegmentedBucketBarChart',
  TOPIC_TRENDS_POSITIVE_TABLE = 'TopicTrendsPositive_Table',
  TOPIC_TRENDS_NEGATIVE_TABLE = 'TopicTrendsNegative_Table',
  TOPIC_TRENDS_IN_DEPTH_TOPIC_SELECTOR = 'TopicTrendsInDepth_TopicSelector',
  TOPIC_TRENDS_IN_DEPTH_BAR_CHART = 'TopicTrendsInDepth_BarChart',
  DRIVER_OVERVIEW_BAR_CHART = 'DriverOverview_BarChart',
  DRIVER_OVERVIEW_INFLUENCE_BAR_CHART = 'DriverOverview_InfluenceBarChart',
  DRIVER_OVERVIEW_TABLE = 'DriverOverview_Table',
  DRIVER_OVERVIEW_SCATTER_PLOT = 'DriverOverview_ScatterPlot',
  TOPIC_SENTIMENT_STACKED_BAR_CHART = 'TopicSentiment_StackedBarChart',
  TOPIC_AI_MODEL_SCORE = 'TopicAiModelScore',
  SENTIMENT_GAUGE = 'SentimentGauge',
  SENTIMENT_SEGMENTED_BAR = 'SentimentSegmentedBar',
  SENTIMENT_OVER_TIME_LINE = 'SentimentOverTimeLine',
  SENTIMENT_OVER_TIME_BAR = 'SentimentOverTimeBar',
  CATEGORICAL_TEXT_DISTRIBUTION_INSIGHT_TREEMAP = 'CategoricalTextDistributionInsightTreemap',
  CATEGORICAL_TEXT_DISTRIBUTION_INSIGHT_BAR = 'CategoricalTextDistributionInsightBar',
  TOPIC_BREAKOUT_INSIGHT_HEATMAP = 'TopicBreakoutInsightHeatmap',
  TOPIC_BREAKOUT_INSIGHT_BAR = 'TopicBreakoutInsightBar',
  NUMERICAL_DISTRIBUTION_INSIGHT_HISTOGRAM = 'NumericalDistributionInsightHistogram',
  NUMERICAL_DISTRIBUTION_INSIGHT_CORE_NUMBERS = 'NumericalDistributionInsightCoreNumbers',
}
