<template>
  <NPopover
    placement="bottom"
    trigger="click"
    :show-arrow="false"
    raw
    content-class="bg-input p-1"
    class="rounded"
    :style="`width: ${width}px`"
  >
    <template #activator>
      <NSelect :show="false" :value="selectValue" :style="`width: ${width}px`" />
    </template>

    <div class="d-flex flex-col">
      <NPopover
        placement="right-start"
        trigger="click"
        :show-arrow="false"
        content-class="!p-2 w-[400px] max-h-[600px]"
        class="rounded"
        scrollable
      >
        <template #activator>
          <NButton block quaternary class="justify-start px-2" :type="topicsValue.length ? 'primary' : undefined">
            <span>{{ $t('projects.alerts_view.specific_topic') }}</span>
            <FaIcon class="ml-auto" icon="fa-chevron-right" size="sm" />
          </NButton>
        </template>
        <TopicSelect
          :value="getTopicSelectValue(topicsValue, currentTopics)"
          disabled-sentiment
          :options="getTopicSelectOptions(currentTopics, topicsValue)"
          :absolute="false"
          @update:value="handleSetTopics"
        />
      </NPopover>
      <NPopover
        trigger="click"
        :show-arrow="false"
        placement="right-start"
        content-class="!p-2 w-[400px] max-h-[600px]"
        class="rounded"
        scrollable
      >
        <template #activator>
          <NButton quaternary class="justify-start px-2" :type="categoriesValue.length ? 'primary' : undefined">
            <span class="mr-5">
              {{ $t('projects.alerts_view.topics_in_category') }}
            </span>
            <FaIcon icon="fa-chevron-right" class="ml-auto" size="sm" />
          </NButton>
        </template>
        <CheckboxList
          :only-select-enabled="true"
          :value="categoriesValue"
          :items="currentCategories.map((c) => c.label)"
          @update:value="handleSetCategories"
        />
      </NPopover>
      <NButton quaternary class="justify-start px-2" @click="handleAnyClick()">
        {{ $t('projects.alerts_view.any_topic') }}
      </NButton>
    </div>
  </NPopover>
</template>

<script setup lang="ts">
import CheckboxList from '@/components/CheckboxList.vue'
import TopicSelect from '@/components/topic-assignment/topic/TopicSelect.vue'
import { type RowTopic } from '@/api'
import { computed } from 'vue'
import { getTopicSelectOptions, getTopicSelectValue, useCoding } from '@/composables/useCoding'
import { tolgee } from '@/plugins/i18n'

const props = withDefaults(
  defineProps<{
    projectId: string
    topicsValue: string[]
    categoriesValue: string[]
    width?: number
  }>(),
  { width: 180 }
)

const emit = defineEmits<{
  'update:topics': [value: string[]]
  'update:categories': [value: string[]]
}>()

const { currentCategories, currentTopicsDict, currentTopics } = useCoding(props.projectId)

const selectValue = computed(() => {
  if (!props.topicsValue.length && !props.categoriesValue.length) {
    return tolgee.unwrap(tolgee.t('projects.alerts_view.any_topic')).text
  } else if (props.topicsValue.length) {
    if (props.topicsValue.length === 1) return currentTopicsDict.value[props.topicsValue[0]]?.label

    return `${tolgee.unwrap(tolgee.t('common.topics')).text} (${props.topicsValue.length})`
  } else {
    if (props.categoriesValue.length === 1) return props.categoriesValue[0]
    return `${tolgee.unwrap(tolgee.t('common.categories')).text} (${props.categoriesValue.length})`
  }
})

const handleSetTopics = (value: RowTopic[]) => {
  emit(
    'update:topics',
    value.map((t) => t.id)
  )
  emit('update:categories', [])
}

const handleSetCategories = (value: string[]) => {
  emit('update:categories', value)
  emit('update:topics', [])
}

const handleAnyClick = () => {
  emit('update:topics', [])
  emit('update:categories', [])
}
</script>

<style lang="scss" scoped>
:deep(.n-button__content) {
  flex: 1;
}
</style>
