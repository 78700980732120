<template>
  <!-- Activator -->
  <NButton secondary @click="visible = true">
    <FaIcon icon="far fa-trash" />
  </NButton>

  <!-- Modal content -->
  <NModal v-model:show="visible">
    <NCard :title="$t('team.delete_modal.title')" segmented class="w-xl">
      <NSpin :show="loading">
        <div class="mt-4">
          <span v-html="$t('team.delete_modal.delete_confirm', { name: user.first_name })" />
          <br />
          <b>{{ $t('team.delete_modal.not_revertable') }}</b>
        </div>

        <!-- Radio selection -->
        <NRadioGroup v-model:value="selectedAction" name="radiogroup" class="my-8">
          <NSpace vertical>
            <NRadio value="transfer">
              {{ $t('team.delete_modal.transfer_all_assets') }}
            </NRadio>
            <NRadio value="delete">
              {{ $t('team.delete_modal.do_not_transfer_assets') }}
            </NRadio>
          </NSpace>
        </NRadioGroup>

        <div class="b-1 b-divider-color mb-4 rounded-lg p-2">
          <div class="text-center font-bold">{{ user.first_name }}</div>
        </div>

        <NInput v-model:value="inputText" size="large" :placeholder="$t('team.delete_modal.confirm_delete_input')" />
      </NSpin>

      <template #action>
        <div class="flex justify-end">
          <NButton
            type="error"
            :disabled="!isValid || loading"
            @click="$emit('click:delete', user, selectedAction, closeDialog)"
          >
            {{ $t('common.delete') }}
          </NButton>
        </div>
      </template>
    </NCard>
  </NModal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { TeamMemberResponse } from '@/api'

type TAction = 'transfer' | 'delete'

const props = defineProps<{
  user: TeamMemberResponse
  loading?: boolean
}>()

defineEmits<{
  'click:delete': [user: TeamMemberResponse, selectedAction: TAction, closeDialog: () => void]
}>()

const selectedAction = ref<TAction>('transfer')
const visible = ref(false)
const inputText = ref('')
const isValid = computed(() => `${props.user.first_name}`.toLowerCase() === inputText.value.toLowerCase())

const closeDialog = () => (visible.value = false)

watch(visible, (newVal) => {
  if (newVal) inputText.value = ''
})
</script>

<style lang="scss" scoped></style>
