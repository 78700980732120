/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ColumnDescriptionTemplateVariable = {
  kind?: 'column-description';
  value_type?: ColumnDescriptionTemplateVariable.value_type;
  scope_type?: ColumnDescriptionTemplateVariable.scope_type;
  ref: string;
  identifier?: string;
};
export namespace ColumnDescriptionTemplateVariable {
  export enum value_type {
    STRING = 'string',
  }
  export enum scope_type {
    PROJECT = 'project',
  }
}

