<template>
  <!-- loading -->
  <div v-if="isLoading">
    <NSkeleton text :repeat="3" />
    <div class="mb-6" />
    <NSkeleton text :repeat="20" />
  </div>

  <!-- error -->
  <DataError
    v-else-if="isError && !isFetching"
    :title="error?.name"
    :message="error?.message"
    @click:refetch="refetch"
  />

  <!-- presentation -->
  <div v-else>
    <div class="flex items-center justify-between">
      <div>
        <NH2 class="mb-0">{{ $t('billing.page_title') }}</NH2>
        <div class="text-subheader">{{ $t('billing.page_subheader') }}</div>
      </div>

      <SearchInput v-model:value="searchKey" @update:value="handleSearchUpdate" />
    </div>

    <!-- Filters -->
    <div class="mr-2 mt-5 flex">
      <Container flex class="!rounded-20px">
        <DropdownFilter :filters-config="tableFiltersConfig" @update:filters="handleFiltersUpdate" />
        <SelectedFilters
          :filters-config="tableFiltersConfig"
          @update:filters="handleFiltersUpdate"
          @click:reset="resetFiltersAndSearch"
        />
      </Container>
    </div>

    <NDivider />

    <Container>
      <div class="mb-1 flex items-center justify-between px-2 py-1">
        <div class="font-500">{{ $t('common.matching_results') }}: {{ creditTransactionsRes?.count }}</div>
        <NPopselect
          v-model:value="selectedDownload"
          :options="exportOptions"
          trigger="click"
          :on-update:value="handleStartDownload"
        >
          <NButton type="primary" class="ml-auto" :loading="isDownloading" icon-placement="right" size="small">
            {{ $t('common.download') }}
            <template #icon><FaIcon icon="fa-chevron-down" size="xs" /></template>
          </NButton>
        </NPopselect>
      </div>
      <NDataTable
        remote
        :loading="isFetching"
        :columns="headers"
        :data="creditTransactionsRes?.results"
        :summary="tableSummary"
        :pagination="pagination"
        scroll-x="900"
        @update-sorter="(v) => handleSorterChange(v)"
      >
        <template #empty>
          <NSpace vertical class="my-20 items-center">
            <DataEmpty :message="$t('billing.empty')" @click:reset="resetFiltersAndSearch()">
              <template #icon>
                <FaIcon class="mb-5" icon="fal fa-coins" size="5x" />
              </template>
            </DataEmpty>
          </NSpace>
        </template>
      </NDataTable>
    </Container>
  </div>
</template>

<script setup lang="ts">
import DataEmpty from '@/components/DataEmpty.vue'
import DataError from '@/components/DataError.vue'
import SearchInput from '@/components/SearchInput.vue'
import TagItem from '@/components/TagItem.vue'
import { DropdownFilter, EFilterType, SelectedFilters, type TFilters } from '@/components/filters'
import { useRouter } from 'vue-router'

import { computed, h, reactive, ref } from 'vue'
import { convertKeysToSnakeCase, renderIconButton, renderSorterIcon, renderTableTH, renderTooltip } from '@/utils'
import { formatDate, formatTime } from '@/plugins/i18n'
import { onBeforeRouteLeave } from 'vue-router'
import { useCreditTransactionListConfigQuery, useCreditTransactionListQuery } from '@/api/vq/subscription'
import { useCreditTransactionListLongPolling } from '@/api/long-polling/useCreditTransactionListLongPolling'
import { useDebounceFn } from '@vueuse/core'
import { useTranslate } from '@tolgee/vue'
import type { ArgTypes } from '@/api/vq/subscription'
import type { ConfigOwnerFilterResponse, CreditTransactionRequest, CreditTransactionResponse } from '@/api'
import type { DataTableColumns, DataTableCreateSummary, DataTableSortState, PaginationProps } from 'naive-ui'

const renderHeaderWithTooltip = (title: string, description: string) => {
  return h('div', { class: 'flex gap-1 items-center' }, [
    renderTooltip(renderTableTH(title), '', {
      innerHTML: description,
    }),
  ])
}

const { t } = useTranslate()
const router = useRouter()

const exportOptions = [
  {
    label: 'CSV',
    value: 'csv',
  },
  {
    label: 'JSON',
    value: 'json',
  },
]

const searchKey = ref('')
const selectedDownload = ref(null)
const queryOptions = reactive<ArgTypes<'creditTransactionList'>>({
  page: 1,
  limit: 10,
  projectName: undefined,
  projectId: undefined,
  projectTags: undefined,
  transactionType: undefined,
  ref: undefined,
  format: undefined,
  timestamp: undefined,
  user: undefined,
  orderBy: 'desc:timestamp',
})

const {
  data: creditTransactionsRes,
  isFetching,
  isLoading,
  isError,
  error,
  refetch,
} = useCreditTransactionListQuery(queryOptions)
const { data: creditTransactionListConfig } = useCreditTransactionListConfigQuery()
const { downloadTransactionList, isDownloading, cancelled } = useCreditTransactionListLongPolling()

const tableFiltersConfig = computed<TFilters>(() => [
  {
    type: EFilterType.Checkbox,
    key: 'projectTags',
    label: t.value(`billing.header.tags`),
    items: creditTransactionListConfig.value?.filters.labels.allowed_values,
    value: queryOptions.projectTags ? queryOptions.projectTags.split(',') : undefined,
    renderCheckboxContent: (filter) => h(TagItem, { tag: filter }),
  },
  {
    type: EFilterType.Checkbox,
    key: 'user',
    label: t.value(`billing.header.user`),
    items: creditTransactionListConfig.value?.filters.user.allowed_values,
    value: queryOptions.user ? queryOptions.user.replaceAll('email.contains.i:', '').split(',') : undefined,
    itemValueKey: 'email',
    searchItemKey: 'email',
    renderCheckboxContent: (filter: ConfigOwnerFilterResponse) =>
      h('div', null, [h('div', null, filter.full_name), h('div', { class: 'text-xs c-gray-800' }, filter.email)]),
  },
  {
    type: EFilterType.Checkbox,
    key: 'transactionType',
    label: t.value(`billing.header.transaction_type`),
    items: creditTransactionListConfig.value?.filters.transaction_type.allowed_values.map((v) => ({
      label: t.value(`billing.types.${v}`),
      value: v,
    })),
    itemValueKey: 'value',
    searchItemKey: 'label',
    value: queryOptions.transactionType ? queryOptions.transactionType.split(',') : undefined,
    renderCheckboxContent: (filter: { label: string }) => filter.label,
    renderTagContent: (val) =>
      Array.isArray(val) && val.length === 1
        ? t.value(`billing.types.${val}`)
        : `(${Array.isArray(val) ? val.length : 0})`,
  },
])

const headers: DataTableColumns<CreditTransactionResponse> = [
  {
    title: () => renderTableTH(t.value(`billing.header.date`)),
    key: 'timestamp',
    sorter: 'default',
    renderSorterIcon: ({ order }) => renderSorterIcon(order),
    ellipsis: {
      tooltip: true,
    },
    width: 75,
    render: (row) =>
      h('div', null, [
        h('div', { class: 'font-500' }, formatDate(row.timestamp) || '-'),
        h('div', { class: 'text-xs c-gray-800 line-height-4' }, formatTime(row.timestamp) || '-'),
      ]),
  },
  {
    title: () => renderTableTH(t.value('billing.header.question')),
    key: 'project_name',
    className: 'cursor-pointer',
    width: 200,
    ellipsis: {
      tooltip: true,
    },
    cellProps(row) {
      return {
        onClick: () => router.push(`/projects/${row.project_id}`),
      }
    },
    render: (row) =>
      h('div', null, [
        h('div', { class: 'font-500' }, row.project_name),
        h('div', { class: 'text-xs c-gray-800 line-height-4' }, row.column_name),
      ]),
  },
  {
    title: () =>
      h('div', { class: 'flex items-center' }, [
        renderHeaderWithTooltip(t.value(`billing.header.user`), t.value(`billing.helptip.user`)),
        h('span', { class: 'mx-1 text-xs c-gray-800' }, ' / '),
        renderHeaderWithTooltip(t.value(`billing.header.payer`), t.value(`billing.helptip.payer`)),
      ]),
    key: 'payer_name',
    width: 120,
    render: (row) =>
      h('div', null, [
        h('div', { class: 'font-500' }, row.user_name || t.value(`billing.deleted_user`)),
        h('div', { class: 'text-xs c-gray-800 line-height-4' }, row.payer_name),
      ]),
  },
  {
    title: () => renderTableTH(t.value(`billing.header.transaction_type`)),
    key: 'transaction_type',
    width: 120,
    render: (ct) => h('span', { class: 'font-500 c-gray-800' }, t.value(`billing.types.${ct.transaction_type}`)),
  },
  {
    title: () => renderHeaderWithTooltip(t.value(`billing.header.monthly`), t.value(`billing.helptip.monthly`)),
    key: 'amount_recurring',
    sorter: 'default',
    renderSorterIcon: ({ order }) => renderSorterIcon(order),
    width: 150,
    render: (row) => [
      h('span', { class: 'font-bold mr-1' }, row.amount_recurring),
      h('em', null, `(${row.balance_recurring === null ? '?' : row.balance_recurring})`),
    ],
  },
  {
    title: () => renderHeaderWithTooltip(t.value(`billing.header.oneoff`), t.value(`billing.helptip.oneoff`)),
    key: 'amount_oneoff',
    sorter: 'default',
    renderSorterIcon: ({ order }) => renderSorterIcon(order),
    width: 150,
    render: (row) => [
      h('span', { class: 'mr-1' }, row.amount_oneoff),
      h('em', null, `(${row.balance_oneoff === null ? '?' : row.balance_oneoff})`),
    ],
  },
]

const pagination = computed<PaginationProps>(() => ({
  page: queryOptions.page || 1,
  pageSize: queryOptions.limit,
  pageSlot: 7,
  simple: true,
  next: () => renderIconButton('fa-chevron-right'),
  prev: () => renderIconButton('fa-chevron-left'),
  itemCount: creditTransactionsRes.value?.count || 0,
  displayOrder: ['size-picker', 'pages', 'quick-jumper'],
  onChange: async (page: number) => {
    queryOptions.page = page
    await refetch()
  },
  onUpdatePageSize: async (pageSize: number) => {
    queryOptions.limit = pageSize
    queryOptions.page = 1
    await refetch()
  },
}))

const tableSummary: DataTableCreateSummary<CreditTransactionResponse> = () => {
  return {
    transaction_type: {
      value: h('span', { class: 'font-bold color-neutral-400' }, 'Total'),
      colSpan: 1,
    },
    amount_recurring: {
      value: h('span', { class: 'font-bold' }, creditTransactionsRes.value?.sum_deductions_recurring),
      colSpan: 1,
    },
    amount_oneoff: {
      value: h('span', { class: 'font-bold' }, creditTransactionsRes.value?.sum_deductions_oneoff),
      colSpan: 1,
    },
  }
}

const handleSorterChange = async (sort: DataTableSortState) => {
  let orderBy = 'desc:timestamp'

  if (typeof sort.order === 'string') {
    const order = sort.order.includes('desc') ? 'desc' : 'asc'

    orderBy = `${order}:${sort.columnKey}`
  }

  queryOptions.orderBy = orderBy
  await refetch()
}

const handleFiltersUpdate = async (attr: keyof typeof queryOptions, val: Array<string | number>) => {
  if (!val || (val && !val.length)) {
    queryOptions[attr] = undefined
    await refetch()
    return
  }

  // keep it in this way to add more exceptions in the if case below when needed
  let value = undefined

  if (attr === 'user') value = val.map((v) => `email.contains.i:${v}`).join(',')
  else value = val.join(',')

  queryOptions[attr] = value as never

  await refetch()
}

const handleSearchUpdate = useDebounceFn(async (val: string) => {
  queryOptions.projectName = val ? `contains.i:${val}` : undefined
  queryOptions.page = 1
  await refetch()
}, 500)

const resetFiltersAndSearch = async () => {
  tableFiltersConfig.value.forEach((filter) => {
    const key = filter.key as keyof typeof queryOptions

    queryOptions[key] = undefined
  })
  queryOptions.page = 1
  queryOptions.projectName = undefined
  searchKey.value = ''

  await refetch()
}

const handleStartDownload = (format: CreditTransactionRequest.format) => {
  const requestBody = convertKeysToSnakeCase<CreditTransactionRequest>({ ...queryOptions, format })

  downloadTransactionList({
    requestBody,
  })
}

// if downloading is in progress, ask for confirmation before leaving the page
onBeforeRouteLeave(() => {
  let answer = true

  if (isDownloading.value) answer = window.confirm(t.value('billing.download_in_progress'))

  if (answer) {
    cancelled.value = true
  }

  return answer
})
</script>

<style lang="scss" scoped></style>
