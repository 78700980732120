<template>
  <NModal :show="modelValue" :mask-closable="false">
    <NCard
      :class="`w-full ${!anonymizeClassesStep ? 'max-w-[450px]' : 'max-w-[650px]'}`"
      closable
      :title="
        anonymizeClassesStep ? $t('projects.new_project.anonymization_step_title') : $t('projects.new_project.new')
      "
      rounded-lg
      size="small"
      @close="closeModal"
    >
      <NSpin :show="isSubmitting" class="w-full">
        <NForm v-if="!anonymizeClassesStep">
          <NGrid cols="12" item-responsive :x-gap="12" responsive="screen">
            <NFormItemGi span="12" :label="$t('projects.new_project.name')" path="name" v-bind="nameAttrs.fieldAttrs">
              <NInput v-model:value="name" placeholder="" v-on="nameAttrs.inputListeners" />
            </NFormItemGi>

            <NFormItemGi class="project-description" span="12" path="description" v-bind="descriptionAttrs.fieldAttrs">
              <template #label>
                <CTooltip :label="$t('projects.new_project.description_tooltip')">
                  {{ $t('projects.new_project.description') }}
                </CTooltip>
              </template>

              <NInput
                v-model:value="description"
                class="hint-textarea"
                :autosize="{
                  minRows: 4,
                }"
                type="textarea"
                :placeholder="$t('projects.new_project.description_hint')"
                v-on="descriptionAttrs.inputListeners"
              />
            </NFormItemGi>

            <!-- tagOptions -->
            <NFormItemGi span="12" :label="$t('projects.new_project.tags')" path="tags">
              <NSelect v-model:value="tags" multiple filterable :render-tag="renderTag" :options="tagOptions" />
            </NFormItemGi>

            <NFormItemGi
              span="12"
              :label="$t('projects.new_project.language')"
              path="language"
              v-bind="languageAttrs.fieldAttrs"
            >
              <NSelect
                v-model:value="language"
                filterable
                :options="languageOptions"
                v-on="languageAttrs.inputListeners"
              />
            </NFormItemGi>

            <NFormItemGi
              span="12"
              label=""
              path="anonymization"
              :show-label="false"
              :show-feedback="false"
              v-bind="anonymizationAttrs.fieldAttrs"
            >
              <div
                class="flex w-full cursor-pointer items-center justify-between"
                @click="anonymization = !anonymization"
              >
                <div class="flex items-center">
                  <CTooltip :label="$t('projects.new_project.anonymization_helptip')">
                    <label class="c-header-text-color mr-1">{{ $t('projects.new_project.anonymization') }}</label>
                  </CTooltip>
                </div>
                <NSwitch :value="anonymization" placeholder="" v-on="anonymizationAttrs.inputListeners" />
              </div>
            </NFormItemGi>
            <NFormItemGi
              span="12"
              label=""
              path="translations_enabled"
              :show-label="false"
              :show-feedback="false"
              v-bind="translationsEnabledAttrs.fieldAttrs"
              :class="translationsEnabled ? 'mb-0' : 'mb-4'"
            >
              <div
                class="flex w-full cursor-pointer items-center justify-between"
                @click="translationsEnabled = !translationsEnabled"
              >
                <div class="flex items-center">
                  <CTooltip :label="$t('projects.new_project.translations_helptip')">
                    <label class="c-header-text-color mr-1">{{ $t('projects.new_project.translations') }}</label>
                  </CTooltip>
                </div>
                <NSwitch :value="translationsEnabled" placeholder="" v-on="translationsEnabledAttrs.inputListeners" />
              </div>
            </NFormItemGi>
            <NFormItemGi
              v-if="translationsEnabled"
              span="12"
              :label="$t('projects.new_project.translation_engine')"
              path="translation_engine"
              v-bind="translationEngineAttrs.fieldAttrs"
              class="mt-2 flex-col"
            >
              <NSelect
                v-model:value="translationEngine"
                :options="translationsEngineOptions"
                v-on="translationEngineAttrs.inputListeners"
              />
            </NFormItemGi>
          </NGrid>
          <CMessage v-if="translationsEnabled" class="mb-3" type="info">
            <span
              v-html="
                translationEngine === ProjectCreateUIRequest.translation_engine.GOOGLE_TRANSLATE
                  ? $t('projects.new_project.translations_info_google')
                  : $t('projects.new_project.translations_info_microsoft')
              "
            />
          </CMessage>
        </NForm>
        <div v-else>
          <div class="mb-4">{{ $t('projects.new_project.anonymization_description') }}</div>
          <NCheckboxGroup v-model:value="enabledAnonymizeClasses">
            <NGrid :y-gap="8" :cols="3">
              <NGi v-for="entity in DEFAULT_ANONYMIZATION_CLASSES" :key="entity">
                <NCheckbox :value="entity">
                  <div class="flex items-center gap-1">
                    <CTooltip
                      placement="bottom"
                      :label="$t(`projects.new_project.anonymization_classes.${entity}_description`)"
                    >
                      {{ $t(`projects.new_project.anonymization_classes.${entity}`) }}
                    </CTooltip>
                  </div>
                </NCheckbox>
              </NGi>
            </NGrid>
          </NCheckboxGroup>

          <NCollapse arrow-placement="right" class="my-4">
            <NCollapseItem name="advanced-settings">
              <template #header>
                <div>
                  <div class="font-500">
                    {{ $t('projects.new_project.advanced_settings') }}
                  </div>
                  <div class="c-default-text-color">
                    {{ $t('projects.new_project.advanced_settings_description') }}
                  </div>
                </div>
              </template>
              <NCheckboxGroup v-model:value="enabledAdvancedAnonymizeClasses">
                <NGrid :y-gap="8" :cols="3">
                  <NGi v-for="entity in ADVANCED_ANONYMIZATION_CLASSES" :key="entity">
                    <NCheckbox :value="entity">
                      <div class="flex items-center gap-1">
                        <CTooltip
                          placement="bottom"
                          :label="$t(`projects.new_project.anonymization_classes.${entity}_description`)"
                        >
                          {{ $t(`projects.new_project.anonymization_classes.${entity}`) }}
                        </CTooltip>
                      </div>
                    </NCheckbox>
                  </NGi>
                </NGrid>
              </NCheckboxGroup>
              <div class="font-500 c-header-text-color mt-5">
                {{ $t('projects.new_project.allow_classes') }}
              </div>
              <div class="mb-2" v-html="$t('projects.new_project.allow_classes_description')" />
              <div ref="termInputContainerRef">
                <NDynamicTags v-model:value="allowList" class="mb-4" :max="1000">
                  <template #trigger="{ activate, disabled }">
                    <NButton size="tiny" quaternary :disabled="disabled" @click="activate()">
                      {{ $t('projects.new_project.add_term') }}
                      <FaIcon icon="fa-plus" class="ml-1" />
                    </NButton>
                  </template>
                  <template #input="{ submit, deactivate }">
                    <NInput
                      ref="allowListTermsInputRef"
                      v-model:value="allowListTermsValue"
                      passively-activated
                      size="small"
                      class="w-16"
                      autosize
                      placeholder=""
                      @keydown.enter="submit(allowListTermsValue)"
                      @blur="deactivate"
                    />
                  </template>
                </NDynamicTags>
              </div>
            </NCollapseItem>
          </NCollapse>
        </div>

        <div class="flex justify-end">
          <NButton
            v-if="anonymizeClassesStep"
            type="default"
            size="small"
            class="mr-2"
            @click="
              () => {
                anonymization = false
                anonymizeClassesStep = false
              }
            "
          >
            {{ $t('common.back') }}
          </NButton>
          <NButton type="primary" size="small" :disabled="!isFormValid || isPending" @click="createProject">
            {{ $t('common.continue') }}
          </NButton>
        </div>
      </NSpin>
    </NCard>
  </NModal>
</template>

<script setup lang="ts">
import TagItem from '@/components/TagItem.vue'
import { DetectableEntitiesWDefault, ProjectCreateUIRequest } from '@/api'
import { type InputInst, type SelectRenderTag } from 'naive-ui'
import { boolean, object, string } from 'yup'
import { computed, h, nextTick, ref, watch } from 'vue'
import { tolgee } from '@/plugins/i18n'
import { useProjectCreateMutation } from '@/api/vq/projects'
import { useProjectListConfigQuery } from '@/api/vq/projects'
import { useRouter } from 'vue-router'
import { useTranslate } from '@tolgee/vue'
import { useValidatedForm } from '@/plugins/form-validation/'

const props = defineProps<{
  modelValue?: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [value: boolean | undefined]
}>()

const DEFAULT_ANONYMIZATION_CLASSES = [
  DetectableEntitiesWDefault.DRIVER_LICENSE,
  DetectableEntitiesWDefault.EMAIL_ADDRESS,
  DetectableEntitiesWDefault.HEALTHCARE_NUMBER,
  DetectableEntitiesWDefault.LOCATION_ADDRESS,
  DetectableEntitiesWDefault.NAME,
  DetectableEntitiesWDefault.NUMERICAL_PII,
  DetectableEntitiesWDefault.PASSPORT_NUMBER,
  DetectableEntitiesWDefault.PASSWORD,
  DetectableEntitiesWDefault.PASSPORT_NUMBER,
  DetectableEntitiesWDefault.PHONE_NUMBER,
  DetectableEntitiesWDefault.USERNAME,
  DetectableEntitiesWDefault.BANK_ACCOUNT,
  DetectableEntitiesWDefault.CREDIT_CARD,
]

const ADVANCED_ANONYMIZATION_CLASSES = Object.values(DetectableEntitiesWDefault).filter(
  (entity) => entity !== DetectableEntitiesWDefault.DEFAULT && !DEFAULT_ANONYMIZATION_CLASSES.includes(entity)
)

const { t } = useTranslate()
const router = useRouter()

const anonymizeClassesStep = ref(false)
const enabledAnonymizeClasses = ref(DEFAULT_ANONYMIZATION_CLASSES)
const enabledAdvancedAnonymizeClasses = ref([])
const allowList = ref<string[]>([])
const tags = ref([])
const termInputContainerRef = ref<HTMLDivElement>()
const allowListTermsValue = ref('')
const allowListTermsInputRef = ref<InputInst>()

const {
  isFormValid,
  formFields: {
    name: [name, nameAttrs],
    description: [description, descriptionAttrs],
    language: [language, languageAttrs],
    translations_enabled: [translationsEnabled, translationsEnabledAttrs],
    translation_engine: [translationEngine, translationEngineAttrs],
    anonymization: [anonymization, anonymizationAttrs],
  },
  resetForm,
  handleSubmit,
  isSubmitting,
} = useValidatedForm(
  object({
    name: string().required().min(3),
    description: string().optional().max(255),
    language: string().required(),
    translations_enabled: boolean().required(),
    translation_engine: string(),
    anonymization: boolean().required(),
  }),
  {
    name: '',
    language: 'en',
    translations_enabled: false,
    translation_engine: ProjectCreateUIRequest.translation_engine.GOOGLE_TRANSLATE,
    anonymization: false,
  }
)

const { mutateAsync: createProjectMutaton, isPending } = useProjectCreateMutation()
const { data: configData } = useProjectListConfigQuery()

const tagOptions = computed(() => {
  return configData?.value?.tabs
    .filter((tab) => tab.type === 'tag')
    .map((tag) => ({
      label: tag.name,
      value: tag.value,
    }))
})

const languageOptions = computed(() => {
  return Object.values(ProjectCreateUIRequest.language).map((lang) => ({
    label: tolgee.unwrap(t.value(`common.language.${lang}`)).text,
    value: lang,
  }))
})

const translationsEngineOptions = computed(() => {
  return Object.values(ProjectCreateUIRequest.translation_engine).map((engine) => ({
    label: tolgee.unwrap(t.value(`common.translation_engine.${engine}`)).text,
    value: engine,
  }))
})

const closeModal = () => {
  emit('update:modelValue', false)
}

const renderTag: SelectRenderTag = ({ option, handleClose }) => {
  return h(TagItem, {
    closable: true,
    tag: option.label as string,
    onClose: handleClose,
  })
}

const createProject = handleSubmit(async (values) => {
  if (values.anonymization && !anonymizeClassesStep.value) {
    anonymizeClassesStep.value = true
    return
  }

  const project = await createProjectMutaton({
    requestBody: {
      name: values.name,
      language: values.language,
      translation_engine: values.translations_enabled ? values.translation_engine : undefined,
      description: values.description,
      tags: tags.value,
      anonymize_pii: values.anonymization
        ? {
            enabled_classes: [...enabledAnonymizeClasses.value, ...enabledAdvancedAnonymizeClasses.value],
            allow_list: allowList.value,
          }
        : undefined,
    } as ProjectCreateUIRequest,
  })

  closeModal()
  router.push(`/projects/${project.id}/upload`)
})

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      anonymizeClassesStep.value = false
      enabledAnonymizeClasses.value = DEFAULT_ANONYMIZATION_CLASSES
      enabledAdvancedAnonymizeClasses.value = []
      allowList.value = []
      tags.value = []

      resetForm({
        values: {
          name: '',
          language: 'en',
        },
      })
    }
  }
)

watch(allowListTermsInputRef, (value) => {
  if (value) {
    nextTick(() => {
      value.select()
    })
  }
})

const handlePaste = (event: ClipboardEvent) => {
  const paste = event.clipboardData?.getData('text/plain')
  const pasteString = paste?.toString() ?? ''
  const pastedStrings = pasteString.split(/(\n\r\t|\n|\r|\t)/gm).filter((s) => s.trim() !== '')

  const remainingSlots = 1000 - allowList.value.length

  if (remainingSlots <= 0) {
    window.$message.warning(t.value('projects.new_project.allow_list_limit_reached'))
    return
  }

  const newEntries = pastedStrings.slice(0, remainingSlots)

  allowList.value = [...allowList.value, ...newEntries]

  if (pastedStrings.length > remainingSlots) {
    window.$message.warning(t.value('projects.new_project.allow_list_partial_paste', { count: remainingSlots }))
  }
}

watch(termInputContainerRef, (value) => {
  if (value) {
    value.addEventListener('paste', handlePaste)
  }
})
</script>

<style lang="scss" scoped>
.project-description {
  :deep(.n-form-item-label__text) {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.hint-textarea {
  :deep(.n-input__placeholder) {
    font-size: 13px !important;
  }
}

:deep(.n-collapse-item__header-main) {
  justify-content: space-between;
}

:deep(.n-dynamic-tags) {
  align-items: center;
}
</style>
