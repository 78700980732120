import { API, STATUS_JSON_URI } from '@/api'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { setLanguage } from '@/plugins/i18n'
import { useAuthStore } from './auth'

export const useAppStore = defineStore('app', () => {
  // state
  const isInitialized = ref(false)
  const maintenance = ref<TMaintenance>({
    active: false,
    msg: '',
  })

  // other dependencies
  const auth = useAuthStore()

  // this function is used only once in route.beforeEach hook
  async function initialize(isPublicRoute: unknown) {
    try {
      await getMaintenanceStatus()
    } catch {
      /* empty */
    }

    // needs to be in try catch, otherwise application will not load at all
    try {
      if (!isPublicRoute) {
        const csrfPass = await auth.CSRFTest()

        if (!csrfPass) {
          console.error('CSRF test failed, logging out')
          await auth.logout()
          return
        }
      }
      await auth.getUser()

      const overrideLang = auth.userLanguage

      // set app locale
      await setLanguage(overrideLang)
    } catch {
      console.error('Something went wrong when the app was initializing')
    }

    isInitialized.value = true
  }

  async function getMaintenanceStatus() {
    const res = await API.get(STATUS_JSON_URI, {
      params: { ts: new Date().getTime() }, // Make sure no cached version is served
      headers: { Pragma: 'no-cache' },
      withCredentials: false,
    })

    if (res.status === 200 && res.data.maintenance && res.data.maintenance.active) {
      maintenance.value = res.data.maintenance as TMaintenance
    }

    return maintenance.value
  }

  return { isInitialized, maintenance, initialize, getMaintenanceStatus }
})
