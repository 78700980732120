/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BooleanComputedColumnResponse = {
  ref?: BooleanComputedColumnResponse.ref;
  pre_rendered_template: string;
  error_message?: string;
  type?: BooleanComputedColumnResponse.type;
  value?: boolean;
};
export namespace BooleanComputedColumnResponse {
  export enum ref {
    REF_TMP_COMPUTED_COLUMN = 'ref_tmp_computed_column',
  }
  export enum type {
    BOOLEAN_COMPUTED_COLUMN = 'boolean-computed-column',
  }
}

