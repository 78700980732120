<template>
  <div
    class="b b-gray-400 inline-block rounded-xl bg-white px-2 shadow-sm dark:bg-transparent"
    :class="{
      'cursor-pointer': clickable,
      'py-0': small,
      'py-1': !small,
      '!bg-c-base': highlighted,
      'border-none': borderless,
      'shadow-none!': borderless,
    }"
  >
    <div class="flex items-stretch gap-2">
      <div
        v-if="!hideCategoryColor"
        class="w-1 rounded-sm"
        :style="{ backgroundColor: categoryColor || 'var(--c-gray-200)' }"
      />
      <CTooltip :disabled="disableCategoryTooltip && disableTopicTooltip" :underline="false">
        <div class="flex-col self-center" :class="{ 'max-w-140px': !maxWidth }" :style="{ maxWidth }">
          <!-- Category -->
          <div v-if="category" class="flex items-center">
            <div
              ref="categoryRef"
              class="font-500 c-header-text-color overflow-hidden text-ellipsis whitespace-nowrap text-xs uppercase"
            >
              <slot name="category-prefix" />
              {{ category }}
            </div>
          </div>

          <!-- Topic -->
          <div v-if="topicLabel" class="flex items-center">
            <span
              ref="topicRef"
              class="w-100% c-header-text-color overflow-hidden text-ellipsis whitespace-nowrap text-xs"
            >
              {{ topicLabel }}
            </span>
          </div>
        </div>

        <template #content>
          <div class="flex-col self-center">
            <div v-if="category" class="flex items-center">
              <div
                class="font-500 c-header-text-color overflow-hidden text-ellipsis whitespace-nowrap text-xs uppercase"
              >
                <slot name="category-prefix" />
                {{ category }}
              </div>
            </div>

            <!-- Topic -->
            <div v-if="topicLabel" class="flex items-center">
              <span
                ref="topicRef"
                class="font-400 w-100% c-header-text-color overflow-hidden text-ellipsis whitespace-nowrap text-xs"
              >
                {{ topicLabel }}
              </span>
            </div>
          </div>
        </template>
      </CTooltip>

      <div class="flex flex-1 items-center justify-end gap-1 self-center">
        <div v-if="sentimentAvailable" class="w-1px bg-divider self-stretch" />

        <NPopover
          v-if="sentimentAvailable && sentimentEditable"
          v-model:show="sentimentSelectVisible"
          placement="bottom-start"
          trigger="click"
          :style="{ width: '120px' }"
        >
          <template #trigger>
            <NButton icon quaternary rounded size="tiny">
              <FaIcon
                class="mr-2 self-center"
                :icon="sentimentMap.getSentimentInformation(sentiment).icon"
                :color="getDefaultSentimentColors(sentiment)"
                size="lg"
              />
              <FaIcon icon="fa-chevron-down" class="ml-auto" size="xs" />
            </NButton>
          </template>
          <div class="flex flex-col">
            <label class="c-neutral-500 mb-1 text-xs font-semibold">{{ $t('projects.topics_view.sentiment') }}:</label>
            <div
              v-for="sentimentOption in CodeSentiments"
              :key="sentimentOption"
              class="my-1 flex items-center hover:cursor-pointer"
              @click.stop.prevent="$emit('update:sentiment', sentimentOption)"
            >
              <FaIcon
                class="mr-2 self-center"
                :icon="sentimentMap.getSentimentInformation(sentimentOption).icon"
                :color="getDefaultSentimentColors(sentimentOption)"
                size="lg"
              />
              <div class="c-neutral-500 hover:c-primary" :class="sentiment === sentimentOption && 'c-primary'">
                {{ sentimentOption }}
              </div>
            </div>
          </div>
        </NPopover>

        <FaIcon
          v-else-if="sentimentAvailable"
          class="self-center"
          :icon="sentimentMap.getSentimentInformation(sentiment).icon"
          :color="getDefaultSentimentColors(sentiment)"
          size="lg"
        />

        <div v-if="!readonly" class="flex">
          <NDivider vertical class="h-auto! ml-0! mr-1! self-stretch" />
          <NButton
            :disabled="loading"
            class="rounded-0 ml-auto self-center px-1 py-0.5"
            text
            ghost
            size="tiny"
            icon
            :focusable="false"
            @click.stop="$emit('click:delete')"
          >
            <FaIcon
              :icon="sentimentEditable ? 'far fa-trash' : 'fa-times'"
              :size="small ? undefined : 'lg'"
              color="neutral-400"
            />
          </NButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CodeSentiments, ProjectCodingRowTextUIResponse, TopicFilterSentiments, TopicSentimentsInResults } from '@/api'
import { computed, ref } from 'vue'
import { useSentimentMap } from './insight-elements/helpers'
import { useThemeStore } from '@/store'

interface ICategoryTopicTagProps {
  category?: string
  topicLabel?: string
  sentiment?:
    | TopicSentimentsInResults
    | ProjectCodingRowTextUIResponse.sentiment_overall
    | TopicFilterSentiments
    | CodeSentiments
    | null
  categoryColor?: string
  showSentiment?: boolean
  sentimentEditable?: boolean
  readonly?: boolean
  loading?: boolean
  clickable?: boolean
  hideCategoryColor?: boolean
  small?: boolean
  highlighted?: boolean
  borderless?: boolean
  maxWidth?: string
}

const props = withDefaults(defineProps<ICategoryTopicTagProps>(), { readonly: true })

defineEmits<{
  'click:delete': []
  'update:sentiment': [value: CodeSentiments]
}>()

const theme = useThemeStore()
const sentimentMap = useSentimentMap()

const categoryRef = ref<HTMLElement>()
const topicRef = ref<HTMLElement>()
const sentimentSelectVisible = ref(false)

const sentimentAvailable = computed(() => props.sentiment !== null && props.sentiment !== 'any' && props.showSentiment)

const disableCategoryTooltip = computed(() => {
  if (!categoryRef.value) return true

  return categoryRef.value.offsetWidth >= categoryRef.value.scrollWidth
})

const disableTopicTooltip = computed(() => {
  if (!topicRef.value) return true

  return topicRef.value.offsetWidth >= topicRef.value.scrollWidth
})

const getDefaultSentimentColors = (sentiment: ICategoryTopicTagProps['sentiment']) => {
  let color = theme.themeVars.infoColor

  if (sentiment === CodeSentiments.POSITIVE) {
    color = theme.themeVars.successColor
  } else if (sentiment === CodeSentiments.NEGATIVE) {
    color = theme.themeVars.errorColor
  }

  return color
}
</script>
