<template>
  <SurveyContainer
    :survey-id="surveyStore.feedbackSurvey?.id"
    :question="$t('posthog.survey_question')"
    :description="$t('posthog.description')"
  >
    <textarea v-model="surveyResponse" rows="4" :placeholder="$t('posthog.input_placeholder')" />
    <template #actions>
      <NButton class="w-100%" size="large" type="primary" @click="submitSurvey">
        {{ $t('common.submit') }}
      </NButton>
    </template>
  </SurveyContainer>
</template>

<script setup lang="ts">
import SurveyContainer from '@/components/survey/SurveyContainer.vue'
import { onMounted, ref, watch } from 'vue'
import { submitFeedbackSurvey } from '@/plugins/posthog'
import { useSurveyStore } from '@/components/survey/survey-store'

const FIVE_MINUTES_MS = 5 * 60 * 1000

const surveyStore = useSurveyStore()
const surveyResponse = ref<string>()
const hasUserInteractedWithSurvey = ref(false)

const submitSurvey = () => {
  if (surveyStore.feedbackSurvey?.id && surveyResponse.value) {
    submitFeedbackSurvey(surveyStore.feedbackSurvey.id, surveyResponse.value)
    closeSurvey()
    window.$message.success('Thank you for your feedback!')
  }
}

watch(
  () => surveyStore.activeSurvey,
  (newVal) => {
    if (newVal?.id === surveyStore.feedbackSurvey?.id) hasUserInteractedWithSurvey.value = true
  }
)

const closeSurvey = () => {
  surveyStore.setActiveSurvey()
  surveyResponse.value = ''
}

onMounted(() => {
  // Show survey after 5 minutes if user hasn't interacted
  setTimeout(() => {
    if (!hasUserInteractedWithSurvey.value) {
      surveyStore.setActiveSurvey(surveyStore.feedbackSurvey)
    }
  }, FIVE_MINUTES_MS)
})
</script>
