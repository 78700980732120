<template>
  <div class="w-100% relative" :class="{ 'px-2': lineCount && isEditing }">
    <CTooltip :disabled="disableTooltip" :underline="tooltipUnderline" :style="{ maxWidth: '400px' }">
      <div class="flex items-center">
        <div
          ref="inputRef"
          :key="changeCount"
          class="min-w-30 editable-text b-1 b-color-transparent overflow-y-auto overflow-x-hidden rounded-lg"
          :class="{
            'n-input--error-status b-color-error! error-state': isEditing && inputErrors.length > 0,
            'b-c-blue-light!': isEditing && inputErrors.length === 0,
            'overflow-x-auto! overflow-y-hidden!': lineCount && lineCount === 1 && isEditing,
            truncate: !isEditing && truncate,
            'opacity-50': loading,
            'cursor-pointer': editable && !isEditing,
            'n-input--focus min-w-36! bg-base min-h-4': isEditing,
            ...inputClass,
          }"
          :contenteditable="isEditing"
          @click="startEditing"
          @keydown="checkMaxLength"
          @keyup="handleInput"
          @blur="saveChanges"
          @keydown.enter.prevent.stop="saveChanges"
          @keydown.esc="stopEditing"
        >
          <slot :is-editing="isEditing" :is-tooltip="false" />
          <slot v-if="!isEditing && !trimmedValue" name="placeholder" />
        </div>
        <NSpin v-if="loading" :size="10" class="ml-2" />
      </div>
      <template #content>
        <slot :is-editing="isEditing" :is-tooltip="true" />
      </template>
    </CTooltip>

    <div v-if="!hideErrors" class="editable-error absolute -left-2">
      <Transition name="fade-slide">
        <span v-if="isEditing && inputErrors.length > 0" class="font-size-sm c-error">
          {{ inputErrors[0] }}
        </span>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { nextTick, ref, watch } from 'vue'
import { trim } from 'lodash-es'
import { useTranslate } from '@tolgee/vue'

type TEditableTextRule = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: (v: any) => string | boolean
}

export interface IEditableTextProps {
  value: string
  editable: boolean
  rules?: TEditableTextRule[]
  dense?: boolean
  lineCount?: number
  noWrap?: boolean
  loading?: boolean
  truncate?: boolean
  tooltip?: boolean
  tooltipUnderline?: boolean
  hideErrors?: boolean
  max?: number
  inputClass?: Record<string, boolean>
}

const props = withDefaults(defineProps<IEditableTextProps>(), {
  truncate: true,
  tooltip: true,
  tooltipUnderline: true,
})

const emit = defineEmits<{ 'update:value': [string] }>()

const { t } = useTranslate()

const isEditing = defineModel<boolean>('isEditing', { default: false })
const inputRef = ref<HTMLElement>()
const inputValue = ref('')
const changeCount = ref(0)
const inputErrors = ref<string[]>([])
const clicked = ref(false)

const disableTooltip = computed(() => {
  if (!inputRef.value || !props.tooltip) return true

  return inputRef.value.offsetWidth >= inputRef.value.scrollWidth || isEditing.value
})

const trimmedValue = computed(() => trim(props.value))

const startEditing = () => {
  if (props.editable && !isEditing.value) {
    clicked.value = true
    isEditing.value = true
    inputValue.value = props.value
    inputErrors.value = []

    nextTick(() => {
      if (inputRef.value) {
        inputRef.value.focus()
      }
    })
  }
}

const checkMaxLength = (event: KeyboardEvent) => {
  if (event.target) {
    const target = event.target as HTMLElement

    const allowedKeys = [
      'Enter',
      'Escape',
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
    ]

    if (props.max && target.innerText.length >= props.max && !allowedKeys.includes(event.key)) {
      event.preventDefault()
    }
  }
}

const handleInput = (event: KeyboardEvent) => {
  if (event.target) {
    const target = event.target as HTMLElement

    inputValue.value = target.innerText
  }
}

const saveChanges = () => {
  if (props.value !== inputValue.value && isEditing.value) {
    if (props.rules) {
      const errors = props.rules
        .map((rule) => rule.validator(trim(inputValue.value)))
        .filter((v) => v !== true)
        .map((v) => (typeof v === 'string' ? v : t.value('formValidation.check_your_input')))

      if (errors.length > 0) {
        inputErrors.value = errors

        inputRef.value?.focus()

        return
      }
    }

    emit('update:value', inputValue.value)
  }

  stopEditing()
}

const stopEditing = () => {
  isEditing.value = false

  nextTick(() => {
    // to re-render the text to match application state
    changeCount.value++
  })
}

const selectAllText = () => {
  if (window.getSelection && document.createRange && inputRef.value) {
    const range = document.createRange()
    const sel = window.getSelection()

    range.selectNodeContents(inputRef.value)
    sel?.removeAllRanges()
    sel?.addRange(range)
  }
}

watch(
  () => props.editable,
  (v) => {
    if (!v) {
      stopEditing()
    }
  }
)

watch(
  () => props.value,
  (v) => {
    inputValue.value = v
  },
  { immediate: true }
)

watch(
  () => isEditing.value,
  (v) => {
    // focus and select all text if programmatically started editing
    if (v && !clicked.value) {
      nextTick(() => {
        inputRef.value?.focus()
        selectAllText()
      })
    }
  }
)

// css variables
const paddingY = computed(() => (props.dense ? '0px' : '2px'))
const marginY = computed(() => (props.dense ? '0px' : '-2px'))
const whiteSpace = computed(() => (props.noWrap ? 'nowrap' : 'normal'))
const scrollWidth = computed(() => (props.lineCount ? 'none' : 'thin'))
const maxHeight = computed(() => (props.lineCount ? `${props.lineCount}rem` : '6.25rem'))
</script>

<style lang="scss" scoped>
.editable-text {
  padding: v-bind(paddingY) 8px;
  margin: v-bind(marginY) -8px;

  &.error-state {
    box-shadow: 0px 0px 2px 0px var(--error-color);
  }
}

.editable-text[contenteditable='true'] {
  max-height: v-bind(maxHeight);
  outline: 0;
  width: fit-content;
  position: relative;
  white-space: v-bind(whiteSpace);
  scrollbar-width: v-bind(scrollWidth);

  &::-webkit-scrollbar {
    display: v-bind(scrollWidth);
  }
}
</style>
