import {
  type ContainerAvailableFiltersResponse,
  FilterKind,
  FiltersService,
  type GenericFilterRequest,
  useCustomFetch,
} from '@/api'
import { EFilterType } from '@/components/filters'
import { type Ref, computed } from 'vue'
import { type TGenericFilterWithResponse, getFiltersConfig } from './filter-helpers'
import { createFilterRequest } from './filter-helpers'
import { useProjectId } from '@/views/reports/report-context'
import type { TFilters } from '@/components/filters'

export const useProjectFiltersConfig = ({
  appliedFilters,
  availableFilters,
  getFilter,
  isLoadingAvailableFilters,
  showOnlyAuxiliaryFilters,
}: {
  appliedFilters: Ref<GenericFilterRequest[]>
  availableFilters: Ref<ContainerAvailableFiltersResponse | undefined>
  getFilter: (key: string) => TGenericFilterWithResponse
  isLoadingAvailableFilters: Ref<boolean>
  showOnlyAuxiliaryFilters?: boolean
}) => {
  const projectId = useProjectId()
  const { fetch } = useCustomFetch(FiltersService.projectFiltersValuesGet)

  const fetchFilterValues = async (colRef: string, kind: FilterKind, type: EFilterType, searchValue?: string) => {
    const searchFilter = searchValue ? createFilterRequest('text-value-contains', colRef, searchValue) : undefined
    const filters = appliedFilters.value.filter((f) => f.column_ref !== colRef) || []

    if (searchFilter) {
      filters.push(searchFilter)
    }

    const res = await fetch({
      projectId,
      requestBody: {
        column_ref: colRef,
        filter_kind: kind,
        filters,
      },
    })

    if (type === EFilterType.Range) return res?.values

    return {
      values: (res?.values_with_counts || []).map((value) => ({
        value: String(value.value),
        label: String(value.value),
        count: typeof value === 'object' && 'count' in value ? value.count : undefined,
        disabled: false,
      })),
      hasMoreValues: res?.has_more_values || false,
    }
  }

  return {
    isLoadingAvailableFilters,
    projectFiltersConfig: computed<TFilters | undefined>(() => {
      return getFiltersConfig({
        availableFilters,
        getFilter,
        fetchFilterValues,
        showOnlyAuxiliaryFilters,
      })
    }),
  }
}
