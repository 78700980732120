import VChart from 'vue-echarts'
import { CanvasRenderer } from 'echarts/renderers.js'
import { computed, reactive } from 'vue'
import { registerTheme, use } from 'echarts/core.js'

import themeDark from './echarts.dark'
import themeLight from './echarts.light'
import { useThemeStore } from '@/store'

type TInitOptions = InstanceType<typeof VChart>['$props']['initOptions']

// defaults
use([CanvasRenderer])

const initialChartThemes = {
  'caplena-light': themeLight,
  'caplena-dark': themeDark,
}

const chartThemes = {
  'caplena-light': reactive({ ...themeLight }),
  'caplena-dark': reactive({ ...themeDark }),
}

// registered themes
Object.entries(chartThemes).forEach(([name, theme]) => {
  registerTheme(name, theme)
})

const initOptions: TInitOptions = {
  renderer: 'canvas',
}

export const useInitECharts = () => {
  const themeStore = useThemeStore()
  const chartsTheme = computed<'caplena-light' | 'caplena-dark'>(() => `caplena-${themeStore.current}`)
  const currentChartTheme = computed(() => chartThemes[chartsTheme.value])

  const resetThemes = () => {
    Object.entries(chartThemes).forEach(([name, theme]) => {
      Object.assign(theme, initialChartThemes[name as keyof typeof chartThemes])
    })
  }

  const getGradientColoringChartTheme = () => {
    return themeStore.customVars.chart.gradient
  }

  return {
    initOptions,
    chartsTheme,
    themeLight,
    themeDark,
    currentChartTheme,
    chartThemes,
    resetThemes,
    getGradientColoringChartTheme,
  }
}
