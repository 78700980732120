import {
  AvailableWidgetId,
  DriverTableInsightElementResponse,
  type DriverValuesTableResponse,
  SegmentKind,
} from '@/api'
import type { TReportSegment } from '@/views/reports/segments/useReportSegments'

export const driverTableMock: DriverTableInsightElementResponse = {
  id: '1',
  kind: 'DriverTableInsight',
  section_id: '1',
  order_id: 1,
  created: '2021-01-01',
  last_modified: '2021-01-01',
  filters: [],
  available_filters: {},
  driver_table_analysis: {
    id: '1',
    name: 'Driver Table Analysis',
    insight_element_id: '1',
    available_identifier: AvailableWidgetId.DRIVER_TABLE_ANALYSIS,
    datasources: [],
    widget_config: {
      id: '1',
      widget_id: '1',
      bucket_configs: [],
    },
    kind: 'DriverOverview_Table',
  },
  driver_summary: {
    id: '2',
    name: 'Driver Summary',
    insight_element_id: '1',
    available_identifier: AvailableWidgetId.DRIVER_SUMMARY,
    datasources: [],
    widget_config: {
      id: '1',
      widget_id: '1',
      bucket_configs: [],
    },
    kind: 'Text',
  },
  driver_scatter_plot_analysis: {
    id: '3',
    name: 'Driver Scatter Plot Analysis',
    insight_element_id: '1',
    available_identifier: AvailableWidgetId.DRIVER_SCATTER_PLOT_ANALYSIS,
    datasources: [],
    widget_config: {
      id: '1',
      widget_id: '1',
      bucket_configs: [],
    },
    kind: 'DriverOverview_ScatterPlot',
  },
  visible_right: DriverTableInsightElementResponse.visible_right.DRIVER_SCATTER_PLOT_ANALYSIS,
}

export const mockSegments: TReportSegment[] = [
  {
    id: 'sg_21y8495',
    name: 'Segment 1',
    kind: SegmentKind.OVERALL,
    created: '2021-01-01',
    last_modified: '2021-01-01',
    color: '#80c7f0',
    is_enabled: true,
  },
  {
    id: 'sg_jk2q76k',
    name: 'Segment 2',
    kind: SegmentKind.USER_DEFINED,
    created: '2021-01-01',
    last_modified: '2021-01-01',
    color: '#6ea8ec',
    is_enabled: true,
  },
]

export const driverTableMockData: DriverValuesTableResponse = {
  kind: 'topic-driver-table-data',
  total_row_count_per_segment: {
    sg_21y8495: 841,
    sg_jk2q76k: 485,
  },
  warnings: [],
  description: '',
  results: [
    {
      id: 'cd_g4d5qzg',
      label: 'Satisfaction',
      category: 'OVERALL SATISFACTION',
      color: 'rgba(128, 199, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 356.0,
          row_count_relative: 0.4233055885850178,
          score: 12.820684523809524,
          color: undefined,
          positive_impact: 0.8443241091228836,
          negative_impact: -3.7361976245720347,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 356.0,
          row_count_relative: 0.734020618556701,
          score: 11.678461538461535,
          color: undefined,
          positive_impact: 1.109772273835628,
          negative_impact: -4.378209610925851,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_g67v4m9',
      label: 'Loyalty',
      category: 'OVERALL SATISFACTION',
      color: 'rgba(128, 199, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 83.0,
          row_count_relative: 0.09869203329369798,
          score: 3.313690476190475,
          color: undefined,
          positive_impact: 1.0857356035175085,
          negative_impact: -0.8854495867529845,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 83.0,
          row_count_relative: 0.1711340206185567,
          score: 2.6005128205128205,
          color: undefined,
          positive_impact: 1.4104456148270108,
          negative_impact: -1.2601914523616535,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9ze0yo9',
      label: 'Customer Service',
      category: 'CUSTOMER SERVICE',
      color: 'rgba(130, 128, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 88.0,
          row_count_relative: 0.10463733650416171,
          score: 2.5879464285714278,
          color: undefined,
          positive_impact: 1.2021511746419409,
          negative_impact: -2.079326698131241,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 88.0,
          row_count_relative: 0.18144329896907216,
          score: 1.7861538461538418,
          color: undefined,
          positive_impact: 1.377867754155223,
          negative_impact: -2.4481091153077457,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9rxn4z9',
      label: 'Coverage',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 79.0,
          row_count_relative: 0.093935790725327,
          score: 1.5074404761904727,
          color: undefined,
          positive_impact: 0.6553107465807072,
          negative_impact: -1.3383924036291028,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 79.0,
          row_count_relative: 0.16288659793814433,
          score: 1.9371794871794834,
          color: undefined,
          positive_impact: 0.9411745704730672,
          negative_impact: -0.6689145648766308,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_93oykk9',
      label: 'Dropped Calls',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 17.0,
          row_count_relative: 0.020214030915576695,
          score: -0.8675595238095215,
          color: undefined,
          positive_impact: 0.4641640898958473,
          negative_impact: -2.0362329340286864,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 17.0,
          row_count_relative: 0.03505154639175258,
          score: -0.5779487179487202,
          color: undefined,
          positive_impact: 0.4912877953398701,
          negative_impact: -1.3254788046775383,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9rxndz9',
      label: 'Sprint',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 30.0,
          row_count_relative: 0.0356718192627824,
          score: -0.787202380952384,
          color: undefined,
          positive_impact: 0.37236057942550393,
          negative_impact: -2.277936027977441,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 30.0,
          row_count_relative: 0.061855670103092786,
          score: -0.5135897435897461,
          color: undefined,
          positive_impact: 0.5601579629173914,
          negative_impact: -2.5073927773861775,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1e4l769',
      label: 'Unlimited',
      category: 'PLAN FEATURES',
      color: 'rgba(130, 128, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 17.0,
          row_count_relative: 0.020214030915576695,
          score: 0.6916666666666644,
          color: undefined,
          positive_impact: 0.7924756156679691,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 17.0,
          row_count_relative: 0.03505154639175258,
          score: 0.9146153846153846,
          color: undefined,
          positive_impact: 1.1274791247385836,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1n6jd0g',
      label: 'Boost Mobile',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 12.0,
          row_count_relative: 0.014268727705112961,
          score: 0.6221726190476167,
          color: undefined,
          positive_impact: 1.0948033595567155,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 12.0,
          row_count_relative: 0.024742268041237112,
          score: 1.6874358974358925,
          color: undefined,
          positive_impact: 1.5722884289328833,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_15d0n79',
      label: 'Service Interruptions',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 7.0,
          row_count_relative: 0.008323424494649227,
          score: -0.5327380952380983,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: -1.2098621847875266,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 7.0,
          row_count_relative: 0.01443298969072165,
          score: -0.23025641025640908,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: -1.0245602961639955,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_g4d5qvg',
      label: 'AT&T',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 36.0,
          row_count_relative: 0.04280618311533888,
          score: 0.49389880952380705,
          color: undefined,
          positive_impact: 0.8062562404521008,
          negative_impact: -2.159932805674566,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 36.0,
          row_count_relative: 0.07422680412371134,
          score: 0.0,
          color: undefined,
          positive_impact: 0.10611993000960539,
          negative_impact: -0.1580470385009301,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1pk7dxg',
      label: 'Cricket',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 6.0,
          row_count_relative: 0.007134363852556481,
          score: 0.4258928571428547,
          color: undefined,
          positive_impact: 0.7874662297126596,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 6.0,
          row_count_relative: 0.012371134020618556,
          score: 0.7543589743589743,
          color: undefined,
          positive_impact: 1.1119205834369632,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1q23rn1',
      label: 'Data',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 13.0,
          row_count_relative: 0.015457788347205707,
          score: -0.4127976190476192,
          color: undefined,
          positive_impact: -0.2839419024370621,
          negative_impact: -1.8743530659437704,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 13.0,
          row_count_relative: 0.026804123711340205,
          score: -0.8753846153846149,
          color: undefined,
          positive_impact: -0.2887667078326188,
          negative_impact: -1.7373394125351966,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_gm42d6g',
      label: 'Signal Strength',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 7.0,
          row_count_relative: 0.008323424494649227,
          score: -0.40982142857142867,
          color: undefined,
          positive_impact: 0.022957826346344162,
          negative_impact: -1.262481825566356,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 7.0,
          row_count_relative: 0.01443298969072165,
          score: -0.23025641025640908,
          color: undefined,
          positive_impact: 0.37725899310884137,
          negative_impact: -1.0297614027949757,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_g4d5ozg',
      label: 'Reception',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 16.0,
          row_count_relative: 0.019024970273483946,
          score: -0.3428571428571431,
          color: undefined,
          positive_impact: 0.691941298037704,
          negative_impact: -1.3327393153643614,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 16.0,
          row_count_relative: 0.032989690721649485,
          score: -0.5410256410256413,
          color: undefined,
          positive_impact: 0.6416587637399038,
          negative_impact: -1.4804460906750407,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_18rmdkg',
      label: 'Value for Money',
      category: 'PRICE',
      color: 'rgba(128, 199, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 22.0,
          row_count_relative: 0.026159334126040427,
          score: 0.3294642857142848,
          color: undefined,
          positive_impact: 0.6840330680923506,
          negative_impact: -0.563115287891263,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 22.0,
          row_count_relative: 0.04536082474226804,
          score: 0.46461538461538376,
          color: undefined,
          positive_impact: 0.7351236497254818,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9rxn479',
      label: 'Price',
      category: 'PRICE',
      color: 'rgba(128, 199, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 169.0,
          row_count_relative: 0.20095124851367419,
          score: -0.3275297619047641,
          color: undefined,
          positive_impact: 0.8569674857605886,
          negative_impact: -2.1957984561515755,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 169.0,
          row_count_relative: 0.34845360824742266,
          score: -0.25205128205128136,
          color: undefined,
          positive_impact: 1.171629059206618,
          negative_impact: -1.578509652774628,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_923wdw1',
      label: 'Deals',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 18.0,
          row_count_relative: 0.02140309155766944,
          score: 0.3119047619047586,
          color: undefined,
          positive_impact: 0.5421200908242225,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 18.0,
          row_count_relative: 0.03711340206185567,
          score: 0.5376923076923064,
          color: undefined,
          positive_impact: 0.9024691715247094,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_g67vzm9',
      label: 'Verizon',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 80.0,
          row_count_relative: 0.09512485136741974,
          score: 0.3090773809523767,
          color: undefined,
          positive_impact: 0.33752553376481,
          negative_impact: -1.4129991959109518,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 80.0,
          row_count_relative: 0.16494845360824742,
          score: 2.7312820512820495,
          color: undefined,
          positive_impact: 0.5727475582988295,
          negative_impact: -1.7482434020036044,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1ye5yzg',
      label: 'Device Quality',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 6.0,
          row_count_relative: 0.007134363852556481,
          score: 0.2607142857142834,
          color: undefined,
          positive_impact: 0.5011847793397485,
          negative_impact: -0.5333367288205106,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 6.0,
          row_count_relative: 0.012371134020618556,
          score: 0.191794871794872,
          color: undefined,
          positive_impact: 0.4578485297674851,
          negative_impact: -0.6119209526656609,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_10ep4y9',
      label: 'MetroPCS',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 10.0,
          row_count_relative: 0.011890606420927468,
          score: 0.24017857142857224,
          color: undefined,
          positive_impact: -0.06905219191351919,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 10.0,
          row_count_relative: 0.020618556701030927,
          score: 0.7397435897435898,
          color: undefined,
          positive_impact: 0.26429601956183757,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1pk7qxg',
      label: 'Brand Perception',
      category: 'OVERALL SATISFACTION',
      color: 'rgba(128, 199, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 54.0,
          row_count_relative: 0.06420927467300833,
          score: 0.2374999999999996,
          color: undefined,
          positive_impact: 0.06347615308431662,
          negative_impact: -1.136581388019624,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 54.0,
          row_count_relative: 0.11134020618556702,
          score: -1.2005128205128224,
          color: undefined,
          positive_impact: -0.6433610466260223,
          negative_impact: -0.6371484494983444,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1v6o469',
      label: 'US Cellular',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 7.0,
          row_count_relative: 0.008323424494649227,
          score: -0.21904761904762182,
          color: undefined,
          positive_impact: 0.25473990043463857,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 7.0,
          row_count_relative: 0.01443298969072165,
          score: -0.26000000000000156,
          color: undefined,
          positive_impact: 0.15767860057980732,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_15d0z79',
      label: 'Affordable',
      category: 'PRICE',
      color: 'rgba(128, 199, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 58.0,
          row_count_relative: 0.06896551724137931,
          score: -0.21458333333333357,
          color: undefined,
          positive_impact: -0.12932941932071956,
          negative_impact: 0.48456728885021544,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 58.0,
          row_count_relative: 0.11958762886597939,
          score: 0.4930769230769191,
          color: undefined,
          positive_impact: 0.6926354232559423,
          negative_impact: 0.16784713932989218,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1ye5kzg',
      label: 'Hidden fees',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 3.0,
          row_count_relative: 0.0035671819262782403,
          score: -0.15967261904762253,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: -0.1818193137699088,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 3.0,
          row_count_relative: 0.006185567010309278,
          score: -0.23025641025640908,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: -0.3856378261767904,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9xey0zg',
      label: 'Local stores',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 5.0,
          row_count_relative: 0.005945303210463734,
          score: -0.15967261904762253,
          color: undefined,
          positive_impact: -0.2915820547353895,
          negative_impact: -0.36863423570293324,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 5.0,
          row_count_relative: 0.010309278350515464,
          score: -0.23025641025640908,
          color: undefined,
          positive_impact: 0.13152396305934916,
          negative_impact: -0.4399622885056079,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9wvwd2g',
      label: 'Provider Comparison',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 9.0,
          row_count_relative: 0.01070154577883472,
          score: -0.15967261904762253,
          color: undefined,
          positive_impact: -0.4282809726498689,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 9.0,
          row_count_relative: 0.018556701030927835,
          score: -0.22692307692307695,
          color: undefined,
          positive_impact: -0.5804810463181743,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_g67v459',
      label: 'WiFi Calling',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 2.0,
          row_count_relative: 0.0023781212841854932,
          score: -0.15967261904762253,
          color: undefined,
          positive_impact: 0.011496311009191237,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 2.0,
          row_count_relative: 0.004123711340206186,
          score: -0.23025641025640908,
          color: undefined,
          positive_impact: 0.10155843043627716,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1j2kpxg',
      label: 'Technical Issues',
      category: 'CUSTOMER SERVICE',
      color: 'rgba(130, 128, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 2.0,
          row_count_relative: 0.0023781212841854932,
          score: 0.13988095238095255,
          color: undefined,
          positive_impact: -0.17906498803182277,
          negative_impact: 0.282833004237478,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 2.0,
          row_count_relative: 0.004123711340206186,
          score: 0.2684615384615405,
          color: undefined,
          positive_impact: -0.2648243217924961,
          negative_impact: 0.43814062007000415,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1q23dn1',
      label: 'Freebies',
      category: 'PLAN FEATURES',
      color: 'rgba(130, 128, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 6.0,
          row_count_relative: 0.007134363852556481,
          score: 0.13988095238095255,
          color: undefined,
          positive_impact: 0.37570645580769224,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 6.0,
          row_count_relative: 0.012371134020618556,
          score: 0.0,
          color: undefined,
          positive_impact: 0.23306831730817695,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1q23rx1',
      label: 'Other Carriers',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 10.0,
          row_count_relative: 0.011890606420927468,
          score: -0.08422619047619241,
          color: undefined,
          positive_impact: 0.1279229193947611,
          negative_impact: -0.7171996409302068,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 10.0,
          row_count_relative: 0.020618556701030927,
          score: -0.2758974358974347,
          color: undefined,
          positive_impact: 0.1042651264120904,
          negative_impact: -0.9049149099457426,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1drvkn9',
      label: 'Device Upgrades',
      category: 'DEVICE SELECTION',
      color: 'rgba(167, 141, 244, 1)',
      values: {
        sg_21y8495: {
          row_count: 3.0,
          row_count_relative: 0.0035671819262782403,
          score: 0.08005952380952408,
          color: undefined,
          positive_impact: -0.09888744344022189,
          negative_impact: 0.17410542175840651,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 3.0,
          row_count_relative: 0.006185567010309278,
          score: 0.23743589743589766,
          color: undefined,
          positive_impact: 0.14617364897012333,
          negative_impact: 0.2924587964083431,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_g7qljp9',
      label: 'Contract',
      category: 'OVERALL SATISFACTION',
      color: 'rgba(128, 199, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 7.0,
          row_count_relative: 0.008323424494649227,
          score: 0.08005952380952408,
          color: undefined,
          positive_impact: 0.44410309008346793,
          negative_impact: -0.10292947315825149,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 7.0,
          row_count_relative: 0.01443298969072165,
          score: 0.0,
          color: undefined,
          positive_impact: 0.24833881382373896,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1j2kqxg',
      label: 'Pricing Competitiveness',
      category: 'PRICE',
      color: 'rgba(128, 199, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 12.0,
          row_count_relative: 0.014268727705112961,
          score: -0.01964285714285907,
          color: undefined,
          positive_impact: 0.1724303571162841,
          negative_impact: 0.043107806678419625,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 12.0,
          row_count_relative: 0.024742268041237112,
          score: 0.2684615384615405,
          color: undefined,
          positive_impact: 0.27786394895233135,
          negative_impact: 0.4352532698276115,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_923wpw1',
      label: 'Roaming',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 2.0,
          row_count_relative: 0.0023781212841854932,
          score: -0.01964285714285907,
          color: undefined,
          positive_impact: 0.17366214216602427,
          negative_impact: -0.6957049664413285,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 2.0,
          row_count_relative: 0.004123711340206186,
          score: -0.23025641025640908,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: -0.8759563175764667,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_glreo3g',
      label: 'Billing',
      category: 'CUSTOMER SERVICE',
      color: 'rgba(130, 128, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 8.0,
          row_count_relative: 0.009512485136741973,
          score: 0.0,
          color: undefined,
          positive_impact: -0.0382442876917195,
          negative_impact: -0.4940201940070518,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 8.0,
          row_count_relative: 0.016494845360824743,
          score: 0.0,
          color: undefined,
          positive_impact: 0.03287842242400027,
          negative_impact: 0.21242578937670392,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_gon87x1',
      label: 'TV',
      category: 'CUSTOMER SERVICE',
      color: 'rgba(130, 128, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 1.0,
          row_count_relative: 0.0011890606420927466,
          score: 0.0,
          color: undefined,
          positive_impact: -0.02969844276963075,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 1.0,
          row_count_relative: 0.002061855670103093,
          score: 0.0,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9kqxwz9',
      label: 'International calling',
      category: 'PLAN FEATURES',
      color: 'rgba(130, 128, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 1.0,
          row_count_relative: 0.0011890606420927466,
          score: 0.0,
          color: undefined,
          positive_impact: 0.26529565092391183,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 1.0,
          row_count_relative: 0.002061855670103093,
          score: 0.0,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9xeypzg',
      label: 'Senior Plans',
      category: 'PLAN FEATURES',
      color: 'rgba(130, 128, 240, 1)',
      values: {
        sg_21y8495: {
          row_count: 0.0,
          row_count_relative: 0.0,
          score: 0.0,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 0.0,
          row_count_relative: 0.0,
          score: 0.0,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9kqxkz9',
      label: 'H2O',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 0.0,
          row_count_relative: 0.0,
          score: 0.0,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 0.0,
          row_count_relative: 0.0,
          score: 0.0,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_gon8dx1',
      label: 'LTE',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 1.0,
          row_count_relative: 0.0011890606420927466,
          score: 0.0,
          color: undefined,
          positive_impact: -0.13515474754363063,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 1.0,
          row_count_relative: 0.002061855670103093,
          score: 0.0,
          color: undefined,
          positive_impact: -0.16340318876374088,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_1e4lo69',
      label: 'Mint',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 1.0,
          row_count_relative: 0.0011890606420927466,
          score: 0.0,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 1.0,
          row_count_relative: 0.002061855670103093,
          score: 0.0,
          color: undefined,
          positive_impact: 0.0,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9wvw62g',
      label: 'Multiple Lines',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 1.0,
          row_count_relative: 0.0011890606420927466,
          score: 0.0,
          color: undefined,
          positive_impact: 0.18938531080868068,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 1.0,
          row_count_relative: 0.002061855670103093,
          score: 0.0,
          color: undefined,
          positive_impact: 0.27938630951191307,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
    {
      id: 'cd_9ze0ow9',
      label: 'Technology Innovation',
      category: 'PROVIDERS',
      color: 'rgba(232, 142, 247, 1)',
      values: {
        sg_21y8495: {
          row_count: 2.0,
          row_count_relative: 0.0023781212841854932,
          score: 0.0,
          color: undefined,
          positive_impact: -0.03064029540741635,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
        sg_jk2q76k: {
          row_count: 2.0,
          row_count_relative: 0.004123711340206186,
          score: 0.0,
          color: undefined,
          positive_impact: -0.041465404332285544,
          negative_impact: 0.0,
          sentiment_disabled_impact: 0.0,
        },
      },
    },
  ],
}
