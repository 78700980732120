<template>
  <SurveyContainer
    :survey-id="surveyStore.g2ReviewSurvey?.id"
    :question="$t('g2_review_survey.survey_question')"
    :description="$t('g2_review_survey.description')"
    @close="surveyStore.postPoneG2SurveyForFiveDays"
  >
    <template #actions>
      <div class="w-100% flex justify-center gap-2">
        <NButton size="large" @click="postPoneSurveyForFiveDays">
          {{ $t('g2_review_survey.not_now') }}
        </NButton>
        <NButton size="large" type="primary" @click="openSurvey">
          {{ $t('g2_review_survey.button_text') }}
        </NButton>
      </div>
    </template>
  </SurveyContainer>

  <div v-if="showSurvey && surveyStore.g2ReviewFormUrl" class="fixed inset-0 z-50 flex items-center justify-center">
    <div class="absolute inset-0 bg-black/80" @click="closeSurvey" />

    <div class="relative z-10 mx-4 aspect-video w-full max-w-4xl">
      <iframe :src="surveyStore.g2ReviewFormUrl" frameborder="0" class="h-full w-full" />
    </div>
  </div>
</template>

<script setup lang="ts">
import SurveyContainer from '@/components/survey/SurveyContainer.vue'
import { onMounted, ref } from 'vue'
import { useSurveyStore } from '@/components/survey/survey-store'

const THREE_MINUTES_MS = 3 * 60 * 1000

const showSurvey = ref(false)
const surveyStore = useSurveyStore()

const closeSurvey = () => {
  showSurvey.value = false
  surveyStore.setActiveSurvey()
  surveyStore.postPoneG2SurveyForFiveDays()
}

const openSurvey = () => {
  showSurvey.value = true
  surveyStore.setActiveSurvey()
  surveyStore.interactedWithG2Survey()
}

const postPoneSurveyForFiveDays = () => {
  surveyStore.setActiveSurvey()
  surveyStore.postPoneG2SurveyForFiveDays()
}

onMounted(() => {
  // Show survey after 3 minutes if user hasn't interacted
  setTimeout(() => {
    if (!surveyStore.hasUserInteractedWithG2Survey()) {
      if (surveyStore.g2ReviewFormUrl) {
        surveyStore.setActiveSurvey(surveyStore.g2ReviewSurvey)
      }
    }
  }, THREE_MINUTES_MS)
})
</script>
