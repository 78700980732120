<template>
  <NLayoutHeader class="app-bar-layout">
    <!-- NOTE: Teleport location for Breadcrumb -->
    <div id="breadcrumb" />

    <!-- Theme / Notifications / User -->
    <NCard class="app-bar-actions" :bordered="false" content-style="padding: 8px 0;">
      <div class="flex gap-2">
        <CTooltip trigger="hover" :label="$t('navigation.switch_to_legacy')">
          <NButton tag="a" :href="legacyRedirectUrl()" rel="noopener noreferrer" icon circle quaternary type="info">
            <FaIcon class="c-default-text-color" :icon="'far fa-arrow-left-to-arc'" size="lg" />
          </NButton>
        </CTooltip>

        <NButton
          v-if="inLocalDevMode"
          type="info"
          quaternary
          icon
          circle
          @click="themeStore.setThemeWithName(themeStore.current === 'dark' ? 'light' : 'dark')"
        >
          <FaIcon
            class="c-default-text-color"
            :icon="themeStore.current === 'dark' ? 'fa-sun' : 'far fa-moon'"
            size="lg"
          />
        </NButton>

        <div class="relative">
          <!-- id and style needs to stay that way for releasesapp to show badge when needed -->
          <!-- https://help.releasesapp.com/faq/how-to-implement-widgets -->
          <NBadge id="releasesapp__unread__badge" dot class="absolute right-0 top-1" :style="{ display: 'none' }" />
          <NButton type="info" quaternary icon circle @click="openReleases">
            <FaIcon class="c-default-text-color" icon="far fa-bullhorn" size="lg" />
          </NButton>
        </div>
        <!-- TODO: Enable later once it is functional -->
        <!-- Search -->
        <!-- <SearchInput collapsable circle /> -->

        <!-- Help Dropdown -->
        <Dropdown v-model="helpDropdownVisible">
          <template #activator>
            <NButton quaternary icon circle>
              <FaIcon icon="far fa-question-circle" size="lg" />
            </NButton>
          </template>

          <NButton
            tag="a"
            href="https://caplena.com/docs/knowledge-base/branches/v3/wktwlwivyiz40-how-can-we-help-you"
            target="_blank"
            rel="noopener noreferrer"
            icon
            block
            quaternary
            class="justify-start px-2"
          >
            <FaIcon icon="far fa-book" class="mr-2" />
            {{ $t('navigation.documentation') }}
          </NButton>

          <NButton
            v-if="surveyStore.feedbackSurvey"
            icon
            block
            quaternary
            class="survey-button justify-start px-2"
            @click="openFeedbackSurvey"
          >
            <FaIcon icon="far fa-comment-dots" class="mr-2" />
            {{ $t('navigation.give_feedback') }}
          </NButton>

          <NButton
            v-if="surveyStore.g2ReviewFormUrl && surveyStore.g2ReviewSurvey"
            tag="a"
            :href="surveyStore.g2ReviewFormUrl"
            target="_blank"
            rel="noopener noreferrer"
            icon
            block
            quaternary
            class="survey-button justify-start px-2"
            @click="surveyStore.interactedWithG2Survey"
          >
            <FaIcon icon="far fa-comment-dots" class="mr-2" />
            {{ $t('navigation.give_g2_review') }}
          </NButton>

          <NButton
            v-if="isFeatureEnabled('intercom')"
            icon
            block
            quaternary
            class="justify-start px-2"
            @click="intercom.show"
          >
            <FaIcon icon="far fa-headset" class="mr-2" />
            {{ $t('navigation.contact_support') }}
          </NButton>

          <!-- Version info -->
          <NButton
            v-if="!isProduction"
            tag="a"
            :href="version.url"
            target="_blank"
            rel="noopener noreferrer"
            icon
            block
            quaternary
            class="justify-start px-2"
          >
            {{ version.label }}
          </NButton>
        </Dropdown>

        <!-- User -->
        <Dropdown v-model="userDropdownVisible">
          <template #activator>
            <NButton text>
              <NAvatar :style="{ backgroundColor: primaryColor }" round>
                {{ user?.first_name[0] }}{{ user?.last_name[0] }}
              </NAvatar>
            </NButton>
          </template>

          <CRouterLink v-slot="{ navigate }" to="/settings">
            <NButton icon block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-cog" class="mr-2" />
              {{ $t('navigation.settings') }}
            </NButton>
          </CRouterLink>

          <CRouterLink v-slot="{ navigate }" to="/team">
            <NButton icon block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-user-group" class="mr-2" />
              {{ $t('navigation.team') }}
            </NButton>
          </CRouterLink>

          <CRouterLink v-slot="{ navigate }" to="/billing">
            <NButton icon block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-wallet" class="mr-2" />
              {{ $t('navigation.billing') }}
            </NButton>
          </CRouterLink>

          <CRouterLink v-slot="{ navigate }" to="/logout">
            <NButton block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-arrow-right-from-bracket" class="mr-2" />
              {{ $t('navigation.logout') }}
            </NButton>
          </CRouterLink>
        </Dropdown>
      </div>
    </NCard>
  </NLayoutHeader>
</template>

<script setup lang="ts">
import Dropdown from '@/components/Dropdown.vue'
// import SearchInput from '@/components/SearchInput.vue'
import { computed, ref } from 'vue'
import { intercom } from '@/plugins/intercom'
import { isFeatureEnabled } from '@/plugins/posthog'
import { useAuthStore, useThemeStore } from '@/store'
import { useSurveyStore } from '@/components/survey/survey-store'

const user = useAuthStore().user
const themeStore = useThemeStore()
const surveyStore = useSurveyStore()

const userDropdownVisible = ref(false)
const helpDropdownVisible = ref(false)
const primaryColor = themeStore.themeVars.primaryColor
const inLocalDevMode = import.meta.env.COMMAND === 'serve' && import.meta.env.MODE === 'development'

const openFeedbackSurvey = () => {
  surveyStore.setActiveSurvey(surveyStore.feedbackSurvey)
}

const isProduction = import.meta.env.MODE === 'production'

const version = computed(() => {
  const url =
    import.meta.env.MODE === 'production'
      ? 'https://news.caplena.com/'
      : `https://github.com/caplena/caplena-app-next/commit/${import.meta.env.CAPP_VERSION}`
  const label = `${import.meta.env.MODE === 'production' ? 'v.' : 'commit:'} ${import.meta.env.CAPP_VERSION}`

  return { url, label }
})

const legacyRedirectUrl = () => {
  let redirectUrl = 'https://caplena.com/app'

  if (import.meta.env.MODE === 'development') {
    redirectUrl = 'https://legacy.dev.caplena.dev'
  } else if (import.meta.env.MODE === 'stage') {
    redirectUrl = 'https://legacy.stage.caplena.dev'
  }

  return redirectUrl
}

const openReleases = () => {
  window.releasesapp.updates()
}
</script>

<style lang="scss" scoped>
.app-bar-layout {
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 0 16px;
  border-top-left-radius: 12px;
  border-bottom: 1px solid var(--divider-color);

  .app-bar-actions {
    width: auto;
    margin-left: auto;
    overflow-y: auto;

    :deep(.n-card__content) {
      display: flex;
      align-items: center;
      padding: 8px 16px;
    }
  }
}
</style>
