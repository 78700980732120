<template>
  <Teleport to="#breadcrumb">
    <div class="font-500 flex items-center">
      <FaIcon icon="fa-folder-closed" class="mr-2 text-[16px] text-gray-600" />
      <slot>
        <div>DEFAULT_BREADCRUMB</div>
      </slot>
    </div>
  </Teleport>
</template>
