<template>
  <ProjectFilters
    v-model:filters="filters"
    class="my-2"
    :project-id="props.projectId"
    :scope="FilterScope.ALERT_ON_TOPIC_TRENDS"
  />

  <NTabs v-model:value="alertType">
    <NTabPane
      :name="AlertOnTopicTrendsPublicType.AUTO_DIGEST"
      :tab="$t('projects.alerts_view.new_alert_modal.tab_auto_digest')"
      :disabled="alertType !== AlertOnTopicTrendsPublicType.AUTO_DIGEST && !!props.alert"
    >
      <CMessage v-if="hasLimitedAccess" type="warning" :title="$t('plan_permissions.not_included')" class="mb-3">
        <span v-html="$t('plan_permissions.partial_contact', { email: 'sales@caplena.com' })" />
      </CMessage>

      <NCard size="small" :title="$t('projects.alerts_view.new_alert_modal.auto_digest_settings_title')">
        <div class="flex items-center justify-between">
          <div class="mr-5 shrink-0">{{ $t('projects.alerts_view.new_alert_modal.send_me_news') }}</div>
          <NSelect
            v-model:value="interval"
            class="max-w-[240px]"
            :options="intervalOptions"
            :placeholder="$t('projects.alerts_view.new_alert_modal.select_interval')"
            v-on="intervalAttrs.inputListeners"
          />
        </div>
        <div class="mt-5 flex items-center justify-between">
          <div class="mr-5 shrink-0">{{ $t('projects.alerts_view.new_alert_modal.significance') }}</div>
          <div class="flex w-full items-center justify-end">
            <NSlider
              v-model:value="thresholdValue"
              class="mr-2 max-w-[170px]"
              :min="50"
              :max="99"
              :step="1"
              :format-tooltip="(value) => `${value}%`"
            />
            <NInputNumber
              v-model:value="thresholdValue"
              size="small"
              min="50"
              :max="99"
              :show-button="false"
              class="max-w-[60px]"
            >
              <template #suffix>
                <span class="ml-2">%</span>
              </template>
            </NInputNumber>
          </div>
        </div>
      </NCard>
    </NTabPane>

    <NTabPane
      :name="AlertOnTopicTrendsPublicType.CHANGES"
      :tab="$t('projects.alerts_view.new_alert_modal.tab_changes')"
      :disabled="(alertType !== AlertOnTopicTrendsPublicType.CHANGES && !!props.alert) || hasLimitedAccess"
    >
      <NCard size="small" :title="$t('projects.alerts_view.new_alert_modal.change_alert_title')">
        <div class="flex items-center justify-between">
          <div class="mr-5 shrink-0">{{ $t('projects.alerts_view.new_alert_modal.notify_me_if') }}</div>
          <div class="flex w-full items-center justify-end">
            <AlertsTopicSelect
              :project-id="projectId"
              :topics-value="topics"
              :categories-value="categories"
              @update:topics="(val) => (topics = val)"
              @update:categories="(val) => (categories = val)"
            />
            <NDropdown trigger="click" :options="metricDropdownOptions">
              <NSelect
                v-model:value="metric"
                :show="false"
                class="ml-2 max-w-[180px]"
                :placeholder="$t('projects.alerts_view.new_alert_modal.select_interval')"
                :options="metricOptions"
              />
            </NDropdown>
          </div>
        </div>
        <div class="mt-5 flex items-center justify-between">
          <div class="mr-5 shrink-0">{{ $t('projects.alerts_view.new_alert_modal.changes_more_than') }}</div>
          <AlertsThresholdSelect
            :alert-type="alertType"
            :threshold-type="thresholdType"
            :threshold-value="thresholdValue"
            @update:threshold-type="(val: AlertThresholdTypeKind) => (thresholdType = val)"
            @update:threshold-value="(val: number) => (thresholdValue = val)"
          />
        </div>
        <div class="mt-5 flex items-center justify-between">
          <div class="mr-5 shrink-0">{{ $t('projects.alerts_view.new_alert_modal.versus') }}</div>
          <NSelect
            v-model:value="interval"
            class="max-w-[240px]"
            :options="intervalOptions"
            :placeholder="$t('projects.alerts_view.new_alert_modal.select_interval')"
            v-on="intervalAttrs.inputListeners"
          />
        </div>
      </NCard>
    </NTabPane>

    <NTabPane
      :name="AlertOnTopicTrendsPublicType.OCCURRENCES"
      :tab="$t('projects.alerts_view.new_alert_modal.tab_occurrences')"
      :disabled="(alertType !== AlertOnTopicTrendsPublicType.OCCURRENCES && !!props.alert) || hasLimitedAccess"
    >
      <NCard size="small" :title="$t('projects.alerts_view.new_alert_modal.occurences_alert_settings_title')">
        <div class="flex items-center justify-between">
          <div class="mr-5 shrink-0">{{ $t('projects.alerts_view.new_alert_modal.notify_me') }}</div>
          <NSelect
            v-model:value="interval"
            class="max-w-[240px]"
            :options="intervalOptions"
            :placeholder="$t('projects.alerts_view.new_alert_modal.select_interval')"
            v-on="intervalAttrs.inputListeners"
          />
        </div>
        <div class="mt-5 flex items-center justify-between">
          <div class="mr-5 shrink-0">{{ $t('projects.alerts_view.new_alert_modal.when') }}</div>
          <div class="flex w-full items-center justify-end">
            <AlertsTopicSelect
              :project-id="projectId"
              :topics-value="topics"
              :categories-value="categories"
              @update:topics="(val) => (topics = val)"
              @update:categories="(val) => (categories = val)"
            />
            <NDropdown trigger="click" :options="metricDropdownOptions">
              <NSelect
                v-model:value="metric"
                :show="false"
                class="ml-2 max-w-[180px]"
                :placeholder="$t('projects.alerts_view.new_alert_modal.select_interval')"
                :options="metricOptions"
              />
            </NDropdown>
          </div>
        </div>
        <div class="mt-5 flex items-center justify-between">
          <div class="mr-5 shrink-0">{{ $t('projects.alerts_view.new_alert_modal.appears_more_than') }}</div>
          <AlertsThresholdSelect
            :alert-type="alertType"
            :threshold-type="thresholdType"
            :threshold-value="thresholdValue"
            @update:threshold-type="(val: AlertThresholdTypeKind) => (thresholdType = val)"
            @update:threshold-value="(val: number) => (thresholdValue = val)"
          />
        </div>
      </NCard>
    </NTabPane>
  </NTabs>
</template>

<script setup lang="ts">
import AlertsThresholdSelect from './AlertsThresholdSelect.vue'
import AlertsTopicSelect from './AlertsTopicSelect.vue'
import ProjectFilters from './ProjectFilters.vue'
import {
  AlertIntervalKind,
  AlertOnTopicTrendsMetricKind,
  AlertOnTopicTrendsPublicType,
  type AlertOnTopicTrendsResponse,
  AlertThresholdTypeKind,
  CodeSentiments,
  FeatureAvailabilityKind,
  FilterScope,
  type GenericFilterRequest,
} from '@/api'
import { computed } from 'vue'
import { useAuthStore } from '@/store'

import { type TNaiveInputPropsForValidation } from '@/plugins/form-validation/'
import { map } from 'lodash-es'
import { useTranslate } from '@tolgee/vue'

const props = defineProps<{
  projectId: string
  alert?: AlertOnTopicTrendsResponse
  intervalAttrs: TNaiveInputPropsForValidation
  intervalOptions: Array<{ label: string; value: AlertIntervalKind }>
}>()

const { t } = useTranslate()
const auth = useAuthStore()

const metric = defineModel<string>('metric', {
  default: AlertOnTopicTrendsMetricKind.TOTAL_COUNT_METRIC,
})
const interval = defineModel<string>('interval', {
  default: AlertIntervalKind.WEEK,
})

const thresholdType = defineModel<AlertThresholdTypeKind>('thresholdType', {
  default: AlertThresholdTypeKind.CHANGE_COUNT_ALERT,
})

const thresholdValue = defineModel<number>('thresholdValue', {
  default: 95,
})

const alertType = defineModel<AlertOnTopicTrendsPublicType>('alertType', {
  default: AlertOnTopicTrendsPublicType.AUTO_DIGEST,
})

const filters = defineModel<Record<string, GenericFilterRequest>>('filters', {
  default: {},
})

const topics = defineModel<string[]>('topics', {
  default: [],
})

const categories = defineModel<string[]>('categories', {
  default: [],
})

const sentiment = defineModel<string | undefined>('sentiment', {
  default: undefined,
})

const hasLimitedAccess = computed(() => auth.user?.subscription.features.alerts === FeatureAvailabilityKind.LIMITED)

const metricOptions = computed(() =>
  map(AlertOnTopicTrendsMetricKind, (kind) => ({
    label:
      kind === AlertOnTopicTrendsMetricKind.SENTIMENT_SHARE_METRIC
        ? sentiment.value
          ? `${t.value(`common.${sentiment.value}`)} sentiment`
          : t.value(`projects.alerts_view.metric.${kind}_change`)
        : t.value(`projects.alerts_view.metric.${kind}`),
    value: kind,
  }))
)

const metricDropdownOptions = computed(() =>
  map(AlertOnTopicTrendsMetricKind, (kind) => {
    if (kind === AlertOnTopicTrendsMetricKind.SENTIMENT_SHARE_METRIC) {
      if (alertType.value === AlertOnTopicTrendsPublicType.CHANGES) {
        return {
          label: t.value(`projects.alerts_view.metric.${kind}_change`),
          key: kind,
          props: {
            onClick: () => {
              sentiment.value = undefined
              metric.value = kind
            },
          },
        }
      } else {
        return {
          label: t.value(`projects.alerts_view.metric.${kind}`),
          key: kind,
          children: map(CodeSentiments, (sen) => ({
            label: t.value(`common.${sen}`),
            key: sen,
            props: {
              onClick: () => {
                sentiment.value = sen
                metric.value = kind
              },
            },
          })),
        }
      }
    }

    return {
      label: t.value(`projects.alerts_view.metric.${kind}`),
      key: kind,
      props: {
        onClick: () => {
          sentiment.value = undefined
          metric.value = kind
        },
      },
      disabled:
        alertType.value === AlertOnTopicTrendsPublicType.OCCURRENCES &&
        kind === AlertOnTopicTrendsMetricKind.EITHER_METRIC,
    }
  })
)
</script>

<style lang="scss" scoped>
.alert-preview {
  :deep(.n-tabs-tab-wrapper) {
    width: 50%;
  }

  :deep(.n-collapse-item__header-main) {
    justify-content: space-between;
  }
}
</style>
