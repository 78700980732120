/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenericWidgetDataWarningResponse } from './GenericWidgetDataWarningResponse';
export type SingleValueData = {
  kind?: SingleValueData.kind;
  total_row_count_per_segment?: Record<string, number>;
  warnings?: Array<GenericWidgetDataWarningResponse>;
  description?: string;
  value?: number;
  segment_id?: string;
  trend_value?: number;
  trend_value_relative?: number;
};
export namespace SingleValueData {
  export enum kind {
    SINGLE_VALUE_DATA = 'single-value-data',
  }
}

