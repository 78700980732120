/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { G2ReviewTokenResponse } from '../models/G2ReviewTokenResponse';
import type { NewsletterSubscribeRequest } from '../models/NewsletterSubscribeRequest';
import type { PersonalizedShowcaseRequest } from '../models/PersonalizedShowcaseRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MarketingService {
  /**
   * Subscribe to our newsletter
   * Adds the supplied email address to our list.
   * @returns any Created
   * @throws ApiError
   */
  public static newsletterSubscribe({
    requestBody,
  }: {
    requestBody: NewsletterSubscribeRequest,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/marketing/newsletter/subscribe/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Request a personalized showcase
   * Submit details to request a personalized showcase.
   * @returns any Created
   * @throws ApiError
   */
  public static personalizedShowcase({
    requestBody,
  }: {
    requestBody: PersonalizedShowcaseRequest,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/marketing/personalized-showcase/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Unprocessable Entity`,
      },
    });
  }
  /**
   * Get G2 token to be able to create a review without signing in
   * @returns G2ReviewTokenResponse OK
   * @throws ApiError
   */
  public static g2ReviewTokenGet(): CancelablePromise<G2ReviewTokenResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/marketing/g2-review-token/',
    });
  }
}
