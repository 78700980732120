<template>
  <NPopover
    :show="isModalVisible"
    trigger="click"
    placement="bottom-start"
    raw
    class="rounded-xl"
    :show-arrow="false"
    @clickoutside="handleOutsideClick"
  >
    <template #trigger>
      <div
        ref="activator"
        :class="['dropdown-activator line-height-0', props.activatorClass]"
        @click="handleModelValueUpdate(!isModalVisible)"
      >
        <slot name="activator" :visible="isModalVisible" />
      </div>
    </template>

    <!-- Dropdown content -->
    <NCard :content-class="`!p-2 rounded-xl ${props.contentClass}`" :bordered="false" @click="handleCardContentClick">
      <div class="flex flex-col gap-1">
        <slot />
      </div>
    </NCard>
  </NPopover>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, useSlots } from 'vue'

interface IDropdownProps {
  modelValue?: boolean
  closeAfterClick?: boolean
  activatorClass?: string
  contentClass?: string
}

const props = withDefaults(defineProps<IDropdownProps>(), {
  modelValue: undefined,
  closeAfterClick: true,
  activatorClass: '',
  contentClass: '',
})

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

const slots = useSlots()
const activator = ref<HTMLElement>()
const internalModelValue = ref(false)

const isModalVisible = computed(() => {
  if (props.modelValue === undefined) return internalModelValue.value

  return props.modelValue
})

const handleModelValueUpdate = (value: boolean) => {
  if (props.modelValue === undefined) {
    // we hare handling dropdown open/close internally
    internalModelValue.value = value
  } else {
    // we are handling dropdown open/close via v-model binding
    emit('update:modelValue', value)
  }
}

const handleCardContentClick = () => {
  if (!props.closeAfterClick) return
  handleModelValueUpdate(false)
}

const handleOutsideClick = (e: MouseEvent) => {
  if (!activator.value?.contains(e.target as HTMLElement)) handleModelValueUpdate(false)
}

onBeforeMount(() => {
  if (!slots.activator) {
    throw new Error(
      // eslint-disable-next-line max-len, vue/max-len
      `[Dropdown.vue]: #activator slot is not defined. You need to define a button in #activator slot to use Dropdown.vue`
    )
  }
})
</script>
