import { type ComputedRef, type InjectionKey, inject, provide } from 'vue'

export const ReportIdKey: InjectionKey<string> = Symbol('reportId')
export const ProjectIdKey: InjectionKey<string> = Symbol('projectId')
export const InsightElementVisibleKey: InjectionKey<ComputedRef<boolean>> = Symbol('insightElementVisible')

export function provideReportId(reportId: string) {
  provide(ReportIdKey, reportId)
}

export function provideProjectId(projectId: string) {
  provide(ProjectIdKey, projectId)
}

export function provideInsightElementVisible(visible: ComputedRef<boolean>) {
  provide(InsightElementVisibleKey, visible)
}

export function useReportId(): string {
  const reportId = inject(ReportIdKey)

  if (!reportId) {
    throw new Error('ReportId was not provided')
  }
  return reportId
}

export function useProjectId(): string {
  const projectId = inject(ProjectIdKey)

  if (!projectId) {
    throw new Error('ProjectId was not provided')
  }
  return projectId
}

export function useInsightElementVisible(): ComputedRef<boolean> {
  const visible = inject(InsightElementVisibleKey)

  if (!visible) {
    throw new Error('InsightElementVisible was not provided')
  }
  return visible
}
