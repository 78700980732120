<template>
  <NAvatar v-if="firstName || lastName" :style="{ backgroundColor: avatarColor }" round>
    <span class="font-500">{{ firstName?.[0] }}{{ lastName?.[0] }}</span>
  </NAvatar>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { stringToColor } from '@/utils/color'

const props = defineProps<{
  firstName?: string
  lastName?: string
}>()

const avatarColor = computed(() => {
  if (!props.firstName && !props.lastName) return ''
  const name = (props.firstName ?? '') + (props.lastName ?? '')

  return stringToColor(name)
})
</script>
