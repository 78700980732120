import {
  AvailableWidgetId,
  type NestedSentimentBucketCategoryTopicValuesWithSignificance,
  type TopicBreakoutBarWidgetResponse,
  type TopicBreakoutHeatmapWidgetResponse,
  TopicBreakoutInsightElementResponse,
  WidgetKind,
} from '@/api'

export const mockTopicBreakoutHeatmapWidgetResponse: TopicBreakoutHeatmapWidgetResponse = {
  id: 'tbh_1',
  name: 'Topic Breakout Heatmap',
  insight_element_id: 'tb_1',
  kind: WidgetKind.TOPIC_BREAKOUT_INSIGHT_HEATMAP,
  available_identifier: AvailableWidgetId.TOPIC_BREAKOUT_HEATMAP,
  datasources: [],
  widget_config: {
    id: 'wg_1',
    widget_id: 'wg_1',
    bucket_configs: [],
  },
}

export const mockTopicBreakoutBarWidgetResponse: TopicBreakoutBarWidgetResponse = {
  id: 'tb_1',
  name: 'Topic Breakout Bar',
  insight_element_id: 'tb_1',
  kind: WidgetKind.TOPIC_BREAKOUT_INSIGHT_BAR,
  available_identifier: AvailableWidgetId.TOPIC_BREAKOUT_BAR,
  datasources: [],
  widget_config: {
    id: 'wg_1',
    widget_id: 'wg_1',
    bucket_configs: [],
  },
}

export const topicBreakoutMock: TopicBreakoutInsightElementResponse = {
  kind: 'TopicBreakoutInsight',
  id: 'tb_1',
  section_id: 'sb_1',
  order_id: 1,
  created: '2024-01-01',
  last_modified: '2024-01-01',
  filters: [],
  available_filters: {},
  topic_breakout_heatmap: mockTopicBreakoutHeatmapWidgetResponse,
  topic_breakout_bar: mockTopicBreakoutBarWidgetResponse,
  visible_left: TopicBreakoutInsightElementResponse.visible_left.TOPIC_BREAKOUT_BAR,
}

export const mockHeatmapWidgetData: NestedSentimentBucketCategoryTopicValuesWithSignificance = {
  kind: 'nested-sentiment-bucket-category-topic-counts',
  warnings: [],
  description:
    // eslint-disable-next-line max-len
    'Topic distribution of column *testing this text* analyzed based on *Which brand is the television purchased most recently in your household?* (n=2969) with filters applied',
  displayed_row_count: 2969,
  total_row_count: 2969,
  results: {
    any: [
      {
        label: 'Samsung',
        total_row_count: 1089,
        categories: [
          {
            id: 'FEATURES',
            label: 'FEATURES',
            value: 9,
            value_relative: 0.008264462809917356,
            alpha: 0.8,
            topics: [],
          },
          {
            id: 'BRANDS',
            label: 'BRANDS',
            value: 15,
            value_relative: 0.013774104683195593,
            alpha: 0.001,
            topics: [],
          },
          {
            id: 'SOUND',
            label: 'SOUND',
            value: 0,
            value_relative: 0,
            alpha: 0.8,
            topics: [],
          },
          {
            id: 'PRICE',
            label: 'PRICE',
            value: 6,
            value_relative: 0.005509641873278237,
            alpha: 0.8,
            topics: [],
          },
          {
            id: 'PERFORMANCE',
            label: 'PERFORMANCE',
            value: 20,
            value_relative: 0.018365472910927456,
            alpha: 0.8,
            topics: [],
          },
          {
            id: 'OTHERS',
            label: 'OTHERS',
            value: 34,
            value_relative: 0.031221303948576674,
            alpha: 0.8,
            topics: [],
          },
        ],
      },
      {
        label: 'LG',
        total_row_count: 538,
        categories: [
          {
            id: 'FEATURES',
            label: 'FEATURES',
            value: 11,
            value_relative: 0.020446096654275093,
            alpha: 0.8,
            topics: [],
          },
          {
            id: 'BRANDS',
            label: 'BRANDS',
            value: 15,
            value_relative: 0.027881040892193308,
            alpha: 0.05,
            topics: [],
          },
          {
            id: 'SOUND',
            label: 'SOUND',
            value: 3,
            value_relative: 0.0055762081784386614,
            alpha: 0.05,
            topics: [],
          },
          {
            id: 'PRICE',
            label: 'PRICE',
            value: 0,
            value_relative: 0,
            alpha: 0.8,
            topics: [],
          },
          {
            id: 'PERFORMANCE',
            label: 'PERFORMANCE',
            value: 12,
            value_relative: 0.022304832713754646,
            alpha: 0.05,
            topics: [],
          },
          {
            id: 'OTHERS',
            label: 'OTHERS',
            value: 18,
            value_relative: 0.03345724907063197,
            alpha: 0.8,
            topics: [],
          },
        ],
      },
      {
        label: 'Vizio',
        total_row_count: 375,
        categories: [
          {
            id: 'FEATURES',
            label: 'FEATURES',
            value: 0,
            value_relative: 0,
            alpha: 0.8,
            topics: [],
          },
          {
            id: 'BRANDS',
            label: 'BRANDS',
            value: 3,
            value_relative: 0.008,
            alpha: 0.8,
            topics: [],
          },
          {
            id: 'SOUND',
            label: 'SOUND',
            value: 0,
            value_relative: 0,
            alpha: 0.8,
            topics: [],
          },
          {
            id: 'PRICE',
            label: 'PRICE',
            value: 3,
            value_relative: 0.008,
            alpha: 0.01,
            topics: [],
          },
          {
            id: 'PERFORMANCE',
            label: 'PERFORMANCE',
            value: 3,
            value_relative: 0.008,
            alpha: 0.8,
            topics: [],
          },
          {
            id: 'OTHERS',
            label: 'OTHERS',
            value: 9,
            value_relative: 0.024,
            alpha: 0.8,
            topics: [],
          },
        ],
      },
    ],
  },
}
