import { AuxiliaryFieldUIResponse, ColumnKind, ColumnTypes, TextToAnalyzeFieldUIResponse } from '@/api'
import { colord, getFormat } from 'colord'
import { colors } from '@unocss/preset-mini'

export type TColorNames = keyof typeof colors

const { rose, pink, fuchsia, purple, violet, indigo, blue, sky, emerald, amber } = colors

const additionalColors = [rose[400], emerald[400], amber[400]]

export const baseColors = [pink[400], fuchsia[400], purple[400], violet[400], indigo[400], blue[400], sky[400]]

export function stringToColor(val: string, useAdditionalColors = false) {
  let hash = 0

  // create a hash from string to use as color index, this will give same output for the same text
  for (let i = 0; i < val.length; i++) {
    hash = val.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }

  const colors = useAdditionalColors ? [...baseColors, ...additionalColors] : baseColors
  const colorIndex = Math.abs(hash) % colors.length

  // return ready to use hex color code
  return colors[colorIndex]
}

export const isColorName = (val: string): val is keyof typeof colors => val in colors

export const getTagColor = (color: string) => {
  if (!color) return undefined
  const format = getFormat(color)
  let parsedColor = ''

  if (format) parsedColor = color
  else if (!format && isColorName(color)) parsedColor = colors[color][500] as string
  else {
    parsedColor = '#dedede'
    console.warn('[ERROR]: Defined color could not be set, setting #dedede as default!')
  }

  return { color: colord(parsedColor).alpha(0.1).toHex(), textColor: parsedColor }
}

export const getTagColorFromString = (val: string) => getTagColor(stringToColor(val, true))

export const getTagColorFromType = (
  type: ColumnTypes | ColumnKind | TextToAnalyzeFieldUIResponse.type | AuxiliaryFieldUIResponse.type
) => {
  return {
    [ColumnKind.DATE]: getTagColor(colors.pink[500]),
    [ColumnKind.TEXT]: getTagColor(colors.sky[500]),
    [AuxiliaryFieldUIResponse.type.ANY]: getTagColor(colors.gray[500]),
    [ColumnKind.NULL]: getTagColor(colors.gray[500]),
    [ColumnKind.NUMERICAL]: getTagColor(colors.violet[500]),
    [ColumnKind.BOOLEAN]: getTagColor(colors.purple[500]),
    [ColumnKind.TEXT_TO_ANALYZE]: getTagColor(colors.blue[500]),
    [ColumnTypes.SOURCE_LANGUAGE]: getTagColor(colors.fuchsia[500]),
  }[type]
}
