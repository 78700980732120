/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TemplateValueType } from './TemplateValueType';
export type ColumnValueTemplateVariable = {
  kind?: 'column-value';
  value_type: TemplateValueType;
  scope_type?: ColumnValueTemplateVariable.scope_type;
  ref: string;
  identifier?: string;
};
export namespace ColumnValueTemplateVariable {
  export enum scope_type {
    ROW = 'row',
  }
}

