<template>
  <NSpin v-if="isLoading" :size="16" show />

  <NSelect
    v-else
    :value="modelValue"
    :options="mappedRoleList"
    filterable
    :placeholder="$t('team.select_a_role')"
    :fallback-option="false"
    :render-label="renderLabel"
    :render-tag="renderTag"
    class="w-100%"
    :class="{ 'nonbordered-select': !bordered }"
    :bordered="bordered"
    @update:value="(val: number) => $emit('update:value', val)"
  >
    <template #arrow>
      <FaIcon icon="fa-chevron-down" class="c-default-text-color ml-2" />
    </template>
  </NSelect>
</template>

<script setup lang="ts">
import { NTag, type SelectOption, type SelectRenderTag } from 'naive-ui'
import { computed, h } from 'vue'
import { getRoleTranslation } from '@/utils'
import { renderTooltip } from '@/utils'
import { useOrganizationRoleListQuery } from '@/api/vq/organization'

type TMappedOrgRole = SelectOption & {
  label: string
  description: string
}

withDefaults(
  defineProps<{
    modelValue?: number
    bordered?: boolean
  }>(),
  {
    bordered: false,
  }
)

defineEmits<{
  'update:value': [value: number]
}>()

const { data, isLoading } = useOrganizationRoleListQuery()

const mappedRoleList = computed<TMappedOrgRole[]>(() => {
  if (!data.value) return []

  return data.value.map((role) => {
    const translatedRole = getRoleTranslation(role.name)

    return {
      label: translatedRole.name,
      description: translatedRole.description,
      value: role.id,
      disabled: !role.applicable,
    }
  })
})

const renderLabel = (option: TMappedOrgRole) => {
  return h('div', { class: 'flex w-full' }, [renderTooltip(h('span', option.label), option.description)])
}
const renderTag: SelectRenderTag = ({ option }) =>
  h(
    NTag,
    {
      bordered: false,
      class: `bg-card-color b-solid b-1 b-divider-color`,
    },
    { default: () => option.label as string }
  )
</script>

<style scoped lang="scss">
:deep(.n-base-selection) {
  box-shadow: none !important;

  .n-base-selection-label {
    background-color: transparent !important;
  }
}

:deep(.n-base-selection-tag-wrapper) {
  .n-tag {
    background-color: var(--card-color);
  }
}

:deep(.n-base-select-menu) {
  .n-base-select-option.n-base-select-option--selected {
    color: var(--default-text-color) !important;

    &.n-base-select-option--disabled {
      .n-base-select-option__check {
        color: var(--default-text-color) !important;
      }
    }
  }
}

.nonbordered-select {
  :deep(.n-base-selection-input) {
    padding-left: 0 !important;
  }

  :deep(.n-base-selection-overlay) {
    padding-left: 0 !important;
  }
}
</style>
