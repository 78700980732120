/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { StarBreakdownWidgetResponse } from './StarBreakdownWidgetResponse';
import type { StarSegmentedBarChartWidgetResponse } from './StarSegmentedBarChartWidgetResponse';
export type StarRatingWoTimeInsightElementResponse = {
  id: string;
  kind?: 'StarRatingWoTimeInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  star_breakdown: StarBreakdownWidgetResponse;
  star_segmented_bar?: StarSegmentedBarChartWidgetResponse;
  visible_left: StarRatingWoTimeInsightElementResponse.visible_left;
};
export namespace StarRatingWoTimeInsightElementResponse {
  export enum visible_left {
    STAR_BREAKDOWN = 'star_breakdown',
    STAR_SEGMENTED_BAR = 'star_segmented_bar',
  }
}

