/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ColumnTranslatedValueTemplateVariable = {
  kind?: 'column-translated-value';
  value_type?: ColumnTranslatedValueTemplateVariable.value_type;
  scope_type?: ColumnTranslatedValueTemplateVariable.scope_type;
  ref: string;
  identifier?: string;
};
export namespace ColumnTranslatedValueTemplateVariable {
  export enum value_type {
    STRING = 'string',
  }
  export enum scope_type {
    ROW = 'row',
  }
}

