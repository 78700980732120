/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ProjectNameTemplateVariable = {
  kind?: 'project-name';
  value_type?: ProjectNameTemplateVariable.value_type;
  scope_type?: ProjectNameTemplateVariable.scope_type;
  identifier?: string;
};
export namespace ProjectNameTemplateVariable {
  export enum value_type {
    STRING = 'string',
  }
  export enum scope_type {
    PROJECT = 'project',
  }
}

