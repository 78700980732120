import { ApiError, ProjectsAsyncService, TaskStatus, getErrorMsg } from '@/api'
import { queryClient } from '@/plugins/vue-query'
import { ref, watch } from 'vue'
import { useMutation, useQuery } from '@tanstack/vue-query'
import type { ColumnBulkDeleteStatus, ProjectRowBulkDeleteStatusResponse, TaskStatusResponse } from '@/api'

// All method keys in ProjectsAsyncService
type TProjectsAsyncServiceMethodKeys = ClassMethodKeys<typeof ProjectsAsyncService>
// Parameter types extractor
export type ArgTypes<K extends TProjectsAsyncServiceMethodKeys> = ClassMethodParamType<typeof ProjectsAsyncService, K>

const resetTaskId = ref<string>()

/***********/
/* QUERIES */
/***********/

export function useProjectRowDeleteStatusQuery(
  params: ArgTypes<'projectCodingRowBulkDeleteAsyncList'>,
  options: TCustomUseQueryOptions<ProjectRowBulkDeleteStatusResponse[]> = {}
) {
  const query = useQuery({
    queryKey: ['project-async-status', params.id],
    queryFn: () =>
      ProjectsAsyncService.projectCodingRowBulkDeleteAsyncList({
        id: params.id,
        ref: params.ref,
      }),
    // Poll every 5 seconds since this is an async status check
    refetchInterval: (query) => {
      if (query.state.data?.find((v) => v.status === TaskStatus.IN_PROGRESS)) {
        return 5000
      }

      return false
    },
    ...options,
  })

  watch(query.isFetching, () => {
    if (!query.isFetching.value && query.data?.value?.find((v) => v.status === TaskStatus.IN_PROGRESS)) {
      // refetch the project row list to get the latest status
      queryClient.invalidateQueries({ queryKey: ['project-row-list', params.id] })
    }
  })

  watch(query.data, () => {
    queryClient.invalidateQueries({ queryKey: ['projects'] })
    queryClient.invalidateQueries({ queryKey: ['projects-config'] })
    queryClient.invalidateQueries({ queryKey: ['project-row-list', params.id] })
    queryClient.invalidateQueries({ queryKey: ['project', params.id] })
  })

  return query
}

export function useProjectQuestionResetAsyncGetQuery(
  params: ArgTypes<'projectQuestionResetAsyncGet'>,
  options: TCustomUseQueryOptions<ColumnBulkDeleteStatus> = {}
) {
  const refetchInterval = ref(5000)

  const query = useQuery({
    queryKey: ['project-question-reset-async-status', params.projectId, params.ref],
    queryFn: () => ProjectsAsyncService.projectQuestionResetAsyncGet(params),
    // Poll every 5 seconds since this is an async status check
    refetchInterval,
    ...options,
  })

  watch(
    query.data,
    (newValue, oldValue) => {
      if (!newValue || newValue.status === TaskStatus.SUCCEEDED) {
        refetchInterval.value = 0
      } else {
        refetchInterval.value = 5000
      }

      if (
        (newValue?.status === TaskStatus.SUCCEEDED &&
          oldValue?.status === TaskStatus.IN_PROGRESS &&
          newValue.task_id === oldValue.task_id) ||
        (newValue?.status === TaskStatus.SUCCEEDED && resetTaskId.value === newValue.task_id)
      ) {
        queryClient.invalidateQueries({ queryKey: ['projects'] })
        queryClient.invalidateQueries({ queryKey: ['project-row-list', params.projectId] })
        queryClient.invalidateQueries({ queryKey: ['project', params.projectId] })

        // reset the page
        window.location.replace(location.pathname)
      }
    },
    { immediate: true }
  )

  return query
}

/*************/
/* MUTATIONS */
/*************/

type TProjectRowDeleteMutationOptions = TCustomUseMutationOptions<
  ProjectRowBulkDeleteStatusResponse,
  ArgTypes<'projectCodingRowBulkDeleteAsync'>
>
export function useAsyncProjectRowDeleteMutation(options: TProjectRowDeleteMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsAsyncService.projectCodingRowBulkDeleteAsync(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
    // onSuccess should not be overwritten
    onSuccess: async (_, params) => {
      return await queryClient.invalidateQueries({ queryKey: ['project-async-status', params.id] })
    },
  })
}

type TProjectQuestionResetMutationOptions = TCustomUseMutationOptions<
  TaskStatusResponse,
  ArgTypes<'projectQuestionResetAsync'>
>
export function useAsyncProjectQuestionResetMutation(options: TProjectQuestionResetMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => ProjectsAsyncService.projectQuestionResetAsync(mutationParams),
    ...options,
    onSuccess: async (data, params) => {
      resetTaskId.value = data.task_id
      return await queryClient.invalidateQueries({
        queryKey: ['project-question-reset-async-status', params.projectId, params.ref],
      })
    },
  })
}
