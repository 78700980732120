/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type StarRatingWoTimeInsightElementUpdateRequest = {
  kind?: 'StarRatingWoTimeInsight';
  order_id?: number;
  visible_left?: StarRatingWoTimeInsightElementUpdateRequest.visible_left;
};
export namespace StarRatingWoTimeInsightElementUpdateRequest {
  export enum visible_left {
    STAR_BREAKDOWN = 'star_breakdown',
    STAR_SEGMENTED_BAR = 'star_segmented_bar',
  }
}

