import mitt from 'mitt'
import { onMounted, onUnmounted } from 'vue'
import type { TopicUIRequest, TopicUIResponse } from '@/api'

export type TBusEvents = {
  'topics-update': TopicUIRequest[]
  'topic-update': TopicUIResponse
  'topics-delete': string[]
  'topic-filter': TopicUIResponse
  'reset-visible-topic-ears': void
  'set-visible-topic-select': boolean
  'bulk-assign-success': void
  'focus-mode-closed': void
  'selected-filter-click': string
  'refresh-row-list': void
  'topics-session-closed': void
  'posthog-user-identified': void
}

const bus = mitt<TBusEvents>()

export const useEventSubscription = (
  e: keyof TBusEvents | Array<keyof TBusEvents>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: (...args: any[]) => void
) => {
  const events = Array.isArray(e) ? e : [e]

  onMounted(() => {
    for (const event of events) bus.on(event, handler)
  })

  onUnmounted(() => {
    for (const event of events) bus.off(event, handler)
  })

  return
}

export const useEventBus = () => bus
