<template>
  <div v-if="showSurvey" id="posthog-survey" class="survey-container max-w-72 shadow-2xl">
    <NButton class="close-icon" quaternary circle icon @click="closeSurvey">
      <FaIcon icon="far fa-times" />
    </NButton>

    <div class="survey-box">
      <div>
        <div>
          <div class="survey-question">{{ question }}</div>
          <div class="survey-question-description">{{ description }}</div>
        </div>

        <slot />

        <div class="bottom-section">
          <div class="buttons">
            <slot name="actions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useSurveyStore } from '@/components/survey/survey-store'

const props = defineProps<{
  surveyId?: string
  question?: string
  description?: string
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const surveyStore = useSurveyStore()
const surveyResponse = ref<string>()

const showSurvey = computed(() => surveyStore.activeSurvey?.id && surveyStore.activeSurvey?.id === props.surveyId)

const closeSurvey = () => {
  surveyStore.setActiveSurvey()
  surveyResponse.value = ''
  emit('close')
}
</script>

<style lang="scss" scoped>
.survey-container {
  position: absolute;
  bottom: 12px;
  right: 12px;
  background-color: var(--c-base);
  border-radius: 12px;
  z-index: 1000;
  padding: 20px 25px 10px;

  :deep(.survey-box > div) {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .survey-question {
      font-size: 16px;
      font-weight: 600;
      padding-right: 10px;
    }

    .survey-question-description {
      font-size: 14px;
      margin-top: 4px;
    }

    & > textarea {
      min-height: 100px;
      font-size: 14px;
      background: var(--card-color);
      outline: none;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      border-radius: 6px;
      border-color: #dfd8cf;
      width: 100%;
      box-sizing: border-box;
    }

    .footer-branding {
      display: none;
    }
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--c-text-light);
    padding: 0;
    line-height: 1;
    z-index: 1001;
  }
}
</style>
