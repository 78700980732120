/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TopicBreakoutInsightElementUpdateRequest = {
  kind?: 'TopicBreakoutInsight';
  order_id?: number;
  visible_left?: TopicBreakoutInsightElementUpdateRequest.visible_left;
};
export namespace TopicBreakoutInsightElementUpdateRequest {
  export enum visible_left {
    TOPIC_BREAKOUT_HEATMAP = 'topic_breakout_heatmap',
    TOPIC_BREAKOUT_BAR = 'topic_breakout_bar',
  }
}

