import { type Component, computed } from 'vue'
import { type RouteLocationNormalizedGeneric, useRoute } from 'vue-router'
import { tolgee } from '@/plugins/i18n'

export const UPLOAD_PATH = 'upload'
export const MERGE_PATH = 'merge'
export const REPLACE_PATH = 'replace'

// Helper function to generate upload-related routes
export const createUploadRoute = (path: string, titleKey: string, component: Component) => {
  return {
    path,
    name: `projectDetail_${path}`,
    component,
    props: (route: RouteLocationNormalizedGeneric) => ({ projectId: route.params.projectId }),
    meta: {
      title: tolgee.unwrap(titleKey).text,
      hideExtraSidebarItems: true,
    },
  }
}

export const useUploadPath = () => {
  const route = useRoute()
  const mergeView = computed(() => route.fullPath.includes(MERGE_PATH))
  const replaceView = computed(() => route.fullPath.includes(REPLACE_PATH))
  const dataEditingView = computed(() => mergeView.value || replaceView.value)

  return {
    mergeView,
    replaceView,
    dataEditingView,
  }
}
