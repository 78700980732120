/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NumericalComputedColumnResponse = {
  ref?: NumericalComputedColumnResponse.ref;
  pre_rendered_template: string;
  error_message?: string;
  type?: NumericalComputedColumnResponse.type;
  value?: number;
};
export namespace NumericalComputedColumnResponse {
  export enum ref {
    REF_TMP_COMPUTED_COLUMN = 'ref_tmp_computed_column',
  }
  export enum type {
    NUMERICAL_COMPUTED_COLUMN = 'numerical-computed-column',
  }
}

