<template>
  <Container flex class="!rounded-20px !inline-flex" :class="{ 'opacity-60': disabled }">
    <div class="flex">
      <div class="flex">
        <DropdownFilter
          :filters-config="projectFiltersConfig || []"
          :filters-loading="isLoadingAvailableFilters"
          :disabled="disabled"
          @update:filters="handleFiltersChange"
        />

        <SelectedFilters
          v-if="projectFiltersConfig"
          :filters-config="projectFiltersConfig"
          :disabled="disabled"
          @update:filters="handleFiltersChange"
        />
      </div>
      <slot />
    </div>
  </Container>
</template>

<script setup lang="ts">
import { DropdownFilter, SelectedFilters, type TPossibleFilterValue } from '@/components/filters'
import { FilterScope, type GenericFilterRequest } from '@/api'
import {
  type TGenericFilterWithResponse,
  createFilterRequest,
  decodeFilterKey,
} from '@/components/filters/filter-helpers'
import { computed } from 'vue'
import { useProjectAvailableFiltersListQuery } from '@/api/vq/filters'
import { useProjectFiltersConfig } from '@/components/filters/useProjectFiltersConfig'

const props = defineProps<{
  projectId: string
  scope: FilterScope
  disabled?: boolean
}>()

const { data: availableFilters, isLoading: isLoadingAvailableFilters } = useProjectAvailableFiltersListQuery({
  scope: props.scope,
  projectId: props.projectId,
})

const filters = defineModel<Record<string, GenericFilterRequest>>('filters', { required: true })

const { projectFiltersConfig } = useProjectFiltersConfig({
  appliedFilters: computed(() => Object.values(filters.value).filter((f) => f !== undefined)),
  availableFilters,
  getFilter: (key) => filters.value[key] as TGenericFilterWithResponse, // casting is needed here since it is not possible match request and response in ts level
  isLoadingAvailableFilters,
})

const handleFiltersChange = (attr: keyof typeof filters.value, val: TPossibleFilterValue) => {
  const { kind, columnRef } = decodeFilterKey(attr)

  const filterValue = createFilterRequest(kind, columnRef, val, false)

  filters.value = {
    ...filters.value,
    [attr]: filterValue,
  }
}
</script>
