/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DateComputedColumnResponse = {
  ref?: DateComputedColumnResponse.ref;
  pre_rendered_template: string;
  error_message?: string;
  type?: DateComputedColumnResponse.type;
  value?: string;
};
export namespace DateComputedColumnResponse {
  export enum ref {
    REF_TMP_COMPUTED_COLUMN = 'ref_tmp_computed_column',
  }
  export enum type {
    DATE_COMPUTED_COLUMN = 'date-computed-column',
  }
}

