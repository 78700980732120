/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TextComputedColumnResponse = {
  ref?: TextComputedColumnResponse.ref;
  pre_rendered_template: string;
  error_message?: string;
  type?: TextComputedColumnResponse.type;
  value?: string;
};
export namespace TextComputedColumnResponse {
  export enum ref {
    REF_TMP_COMPUTED_COLUMN = 'ref_tmp_computed_column',
  }
  export enum type {
    TEXT_COMPUTED_COLUMN = 'text-computed-column',
  }
}

