/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum ColumnTypes {
  NULL = 'null',
  BOOLEAN = 'boolean',
  NUMERICAL = 'numerical',
  DATE = 'date',
  TEXT_TO_ANALYZE = 'text_to_analyze',
  TEXT = 'text',
  SOURCE_LANGUAGE = 'source_language',
}
