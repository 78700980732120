/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { TopicBreakoutBarWidgetResponse } from './TopicBreakoutBarWidgetResponse';
import type { TopicBreakoutHeatmapWidgetResponse } from './TopicBreakoutHeatmapWidgetResponse';
export type TopicBreakoutInsightElementResponse = {
  id: string;
  kind?: 'TopicBreakoutInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  topic_breakout_heatmap: TopicBreakoutHeatmapWidgetResponse;
  topic_breakout_bar: TopicBreakoutBarWidgetResponse;
  visible_left: TopicBreakoutInsightElementResponse.visible_left;
};
export namespace TopicBreakoutInsightElementResponse {
  export enum visible_left {
    TOPIC_BREAKOUT_HEATMAP = 'topic_breakout_heatmap',
    TOPIC_BREAKOUT_BAR = 'topic_breakout_bar',
  }
}

