<template>
  <div>
    <div class="flex items-center justify-between">
      <div>
        <NH2 class="my-4">
          {{ $t('team.user_roles') }}
        </NH2>
      </div>
    </div>

    <Container class="rounded-xl">
      <div class="mb-1.5 mt-0.5 flex items-center justify-between">
        <!-- Filters -->
        <div class="ml-2 flex">
          <DropdownFilter :filters-config="tableFiltersConfig" @update:filters="handleFiltersUpdate" />
          <SelectedFilters
            :filters-config="tableFiltersConfig"
            @update:filters="handleFiltersUpdate"
            @click:reset="resetFiltersAndSearch"
          />
        </div>
        <div class="flex items-center gap-2">
          <SearchInput
            v-model:value="searchKey"
            input-class="bg-card-color b b-divider b-solid shadow-sm h-8"
            @update:value="handleSearchUpdate"
          />
          <NButton
            v-if="hasEditPermission"
            class="ml-auto"
            type="primary"
            icon
            :disabled="!newMemberAvailable"
            @click="$emit('update:showAddMemberModal', true)"
          >
            <template #icon>
              <FaIcon icon="far fa-user-plus" size="xs" />
            </template>
            {{ $t('team.invite') }}
          </NButton>
        </div>
      </div>

      <NDataTable
        remote
        :loading="isLoading"
        :columns="headers"
        :data="teamMembersListRes?.results"
        :pagination="pagination"
        scroll-x="800"
      >
        <template #empty>
          <DataEmpty :message="$t('team.table.no_data')" @click:reset="resetFiltersAndSearch()" />
        </template>
      </NDataTable>
    </Container>
  </div>
</template>

<script setup lang="ts">
import DataEmpty from '@/components/DataEmpty.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import { AccountService, type TeamMemberResponse } from '@/api'
import {
  type ArgTypes,
  useTeamMemberDeleteMutation,
  useTeamMemberListConfigQuery,
  useTeamMemberListQuery,
  useTeamMemberUpdateMutation,
} from '@/api/vq/organization'
import { NButton, NIcon, NTime, useMessage } from 'naive-ui'
import { computed, h, reactive, ref } from 'vue'
import { formatDate, formatTime } from '@/plugins/i18n'
import { getRoleTranslation, renderFaIcon, renderIconButton, renderTableTH } from '@/utils'
import { useAuthStore } from '@/store'
import { useDebounceFn } from '@vueuse/core'
import { useTranslate } from '@tolgee/vue'
import type { DataTableColumn, DataTableColumns, PaginationProps } from 'naive-ui'

import ConfirmTeamMemberDelete from './TeamMembersDeleteModal.vue'
import OrganizationRolesDropdown from './OrganizationRolesDropdown.vue'
import SearchInput from '@/components/SearchInput.vue'
import { DropdownFilter, EFilterType, SelectedFilters, type TFilters } from '@/components/filters'

defineProps<{
  hasEditPermission: boolean
  newMemberAvailable: boolean
}>()

defineEmits<{
  'update:showAddMemberModal': [boolean]
}>()

const { t } = useTranslate()
const auth = useAuthStore()
const message = useMessage()
const queryOptions = reactive<ArgTypes<'teamMemberList'>>({
  page: 1,
  limit: 10,
  roles: undefined,
  activated: undefined,
  email: undefined,
  orderBy: 'desc:date_joined',
})
const searchKey = ref('')
const resendInvitationID = ref()

const { data: teamMembersListRes, isLoading: isLoadingMembers, refetch } = useTeamMemberListQuery(queryOptions)
const { data: teamMembersListConfig } = useTeamMemberListConfigQuery({})
const { mutateAsync: deleteUser, isPending: isDeletingUser } = useTeamMemberDeleteMutation()
const { mutateAsync: updateUser, isPending: isUpdatingUser } = useTeamMemberUpdateMutation()

const isLoading = computed(() => isLoadingMembers.value || isUpdatingUser.value)

const tableFiltersConfig = computed<TFilters>(() => [
  {
    type: EFilterType.Checkbox,
    key: 'roles',
    label: t.value('team.table.roles_label'),
    items: teamMembersListConfig.value?.filters.roles.allowed_values.map((v) => ({
      value: v,
      label: getRoleTranslation(v).name,
    })),
    itemValueKey: 'value',
    searchItemKey: 'label',
    value: queryOptions.roles ? queryOptions.roles.split(',') : undefined,
    renderCheckboxContent: (filter: { label: string }) => filter.label,
    renderTagContent: (val) =>
      Array.isArray(val) && val.length === 1
        ? getRoleTranslation(String(val[0])).name
        : `(${Array.isArray(val) ? val.length : 0})`,
  },
  {
    type: EFilterType.Checkbox,
    key: 'activated',
    label: t.value('team.table.verified_label'),
    items: ['yes', 'no'],
    value: queryOptions.activated === 'True' ? ['yes'] : queryOptions.activated === 'False' ? ['no'] : undefined,
    multiple: false,
    searchable: false,
    renderCheckboxContent: (filter: string) => t.value(`common.${filter}`),
    renderTagContent: (val) => t.value(`common.${val}`),
  },
])

const headers: DataTableColumns<TeamMemberResponse> = [
  {
    title: () => renderTableTH(t.value('team.table.user_label')),
    key: 'user',
    render: (row) =>
      h('div', { class: 'flex items-center gap-2' }, [
        h(UserAvatar, { firstName: row.first_name, lastName: row.last_name }),
        h('div', null, [
          h('div', { class: 'font-500 c-header-text-color' }, row.name),
          h('div', { class: 'text-xs line-height-4' }, row.email),
        ]),
      ]),
  },
  {
    title: () => renderTableTH(t.value('team.table.status_label')),
    key: 'activated',
    render: (row) => {
      const icon = row.activated ? 'fa-check' : 'fa-close'
      const iconComponent = h(NIcon, () => renderFaIcon(icon, { size: '1x' }))
      const activatedText = h('span', { class: 'ms-2 font-500' }, row.activated ? 'Active' : 'Inactive')

      const resendButton =
        auth.hasPermission('team_mgmt') &&
        !row.activated &&
        h(
          NButton,
          {
            loading: !!resendInvitationID.value,
            disabled: !!resendInvitationID.value,
            text: true,
            block: true,
            size: 'tiny',
            class: 'h-12px !w-auto font-400',
            onClick: async () => {
              resendInvitationID.value = row.id
              await AccountService.teamMemberResendConfirmation({ id: row.id })
              message.success(t.value('team.table.invitation_resent'))
              resendInvitationID.value = undefined
            },
          },
          () => 'Resend invite'
        )

      return [iconComponent, activatedText, resendButton]
    },
  },
  {
    title: () => renderTableTH(t.value('team.table.joined_label')),
    key: 'date_joined',
    width: '200px',
    render: (row) =>
      h('div', { class: 'normal-nums' }, [
        h('div', { class: 'font-500' }, formatDate(row.date_joined) || '-'),
        h('div', { class: 'text-xs line-height-4' }, formatTime(row.date_joined) || '-'),
      ]),
  },
  {
    title: () => renderTableTH(t.value('team.table.login_label')),
    key: 'last_login',
    render: (row) => {
      if (!row.last_login) return '-'

      return h(NTime, {
        class: 'font-500',
        time: new Date(row.last_login).getTime(),
        type: 'relative',
      })
    },
  },
  {
    title: () => renderTableTH(t.value('team.table.role_label')),
    key: 'roles',
    width: '280px',
    render: (row) => {
      // check if has permission or user itself
      if (!auth.hasPermission('team_mgmt') || row.id === auth.user?.id) {
        return h('span', { class: 'font-500' }, getRoleTranslation(row.role?.name).name)
      }

      return h(OrganizationRolesDropdown, {
        value: row.role?.id,
        'onUpdate:value': async (roleID) =>
          await updateUser({
            id: row.id,
            requestBody: {
              role: roleID,
            },
          }),
      })
    },
  },
  // show this column only if the user has permission to manage team
  ...(!auth.hasPermission('team_mgmt')
    ? []
    : [
        {
          key: 'actions',
          width: 80,
          render: (row) => {
            // dont show delete button if org owner or self
            if (auth.user?.id === row.id || auth.user?.organization?.root_user_id === row.id) return undefined

            return h(ConfirmTeamMemberDelete, {
              user: row,
              loading: isDeletingUser.value,
              'onClick:delete': async (
                user: TeamMemberResponse,
                action: 'transfer' | 'delete',
                closeDialog: () => void
              ) => {
                await deleteUser(
                  {
                    id: user.id,
                    transferAssets: action === 'transfer',
                  },
                  {
                    onSuccess: () => {
                      window.$message.success(t.value('team.delete_modal.success_message'))
                      return closeDialog()
                    },
                  }
                )
              },
            })
          },
        } as DataTableColumn<TeamMemberResponse>,
      ]),
]

const pagination = computed<PaginationProps>(() => ({
  page: queryOptions.page || 1,
  pageSize: queryOptions.limit,
  pageSlot: 7,
  simple: true,
  next: () => renderIconButton('fa-chevron-right'),
  prev: () => renderIconButton('fa-chevron-left'),
  itemCount: teamMembersListRes.value?.count || 0,
  displayOrder: ['size-picker', 'pages', 'quick-jumper'],
  onChange: async (page: number) => {
    queryOptions.page = page
    await refetch()
  },
  onUpdatePageSize: async (pageSize: number) => {
    queryOptions.limit = pageSize
    queryOptions.page = 1
    await refetch()
  },
}))

const handleSearchUpdate = useDebounceFn(async (val: string) => {
  let value = val === '' ? undefined : val

  if (value) {
    value = `contains.i:${val}`
  }

  queryOptions.email = value
  queryOptions.page = 1
  await refetch()
}, 500)

// This function gets the updates from ProjectListFilters and sets the value to queryOptions accordingly.
// Since query expects specific data, adjusting query data value happens here
const handleFiltersUpdate = async (attr: keyof typeof queryOptions, val: Array<string> | string) => {
  let value = undefined

  if (!val || (val && !val.length)) {
    queryOptions[attr] = value
    await refetch()
    return
  }

  if (attr === 'email') value = (val as string[]).map((v) => `contains.i:${v}`).join(',')
  else if (attr === 'activated') {
    value = val === 'yes' ? 'True' : val === 'no' ? 'False' : undefined
  } else value = (val as string[]).join(',')

  queryOptions[attr] = value as never

  await refetch()
}

const resetFiltersAndSearch = async () => {
  tableFiltersConfig.value.forEach((filter) => {
    const key = filter.key as keyof typeof queryOptions

    queryOptions[key] = undefined
  })
  searchKey.value = ''
  queryOptions.page = 1
  queryOptions.email = undefined

  await refetch()
}
</script>

<style lang="scss" scoped></style>
