<template>
  <CTooltip placement="right" :disabled="disabled || !collapsed" :label="item.name">
    <NButton
      class="sidebar-menu-item w-full rounded-3xl text-[16px]"
      :class="[
        { collapsed: collapsed },
        item.buttonClass,
        { active: isExactActive || (isActive && item.exact === false) },
      ]"
      size="large"
      quaternary
      :disabled="disabled"
      @click="$emit('click')"
    >
      <template v-if="item.icon">
        <NIcon
          class="font-600 mr-2"
          :class="{
            'c-primary': isExactActive || (isActive && item.exact === false),
            'c-gray-500': !(isExactActive || (isActive && item.exact === false)),
          }"
        >
          <FaIcon :icon="item.icon" />
        </NIcon>
      </template>
      <Transition name="fade-slide">
        <span v-if="!collapsed" class="font-600">
          {{ item.name }}
        </span>
      </Transition>
    </NButton>
  </CTooltip>
</template>

<script setup lang="ts">
import { type RouteLocationAsPathGeneric, type RouteLocationAsRelativeGeneric, useLink } from 'vue-router'
import type { TIcon } from '@/plugins/font-awesome'

const props = defineProps<{
  item: {
    name: string
    icon?: TIcon
    buttonClass?: string
    exact?: boolean
    to?: string | RouteLocationAsPathGeneric | RouteLocationAsRelativeGeneric
  }
  collapsed: boolean
  disabled: boolean
}>()

defineEmits<{
  (event: 'click'): void
}>()

const { isExactActive, isActive } = props.item.to
  ? useLink({ to: props.item.to })
  : { isExactActive: false, isActive: false }
</script>

<style scoped lang="scss">
.sidebar-menu-item {
  justify-content: start;
  padding-left: 24px;
  transition: all var(--default-transition);

  &.collapsed {
    padding-left: 14px;
    padding-right: 6px;
  }

  &.active {
    background-color: var(--base-color);
    // border: 1px solid var(--c-gray-300);
    box-shadow: 0px 0px 0px 2px #68573a08;
  }

  // &:not(.active):hover {
  //   background-color: rgba(255, 255, 255, 0.15);
  // }

  :deep(svg) {
    transition: color 0.25s ease-in-out;
  }
}
</style>
