<template>
  <div class="team-view-container">
    <AppBarBreadcrumb>{{ $t('team.title') }}</AppBarBreadcrumb>

    <Container>
      <NGrid v-if="organization" cols="2" x-gap="8px" y-gap="8px">
        <NGi span="600:2 800:1">
          <BigTextBanner :title="$t('team.organization')" :big-text="organization?.name">
            <span v-if="auth.isOrganizationOwner" v-html="$t('team.info_root', { organization: organization?.name })" />
            <!-- for organization user -->
            <span
              v-else
              v-html="
                $t('team.info_org', {
                  organization: organization.name,
                  root_email: organization.root_user_email,
                  root_name: organization.root_user_name,
                })
              "
            />
          </BigTextBanner>
        </NGi>
        <NGi span="600:2 800:1">
          <StatsCard
            :stats="[
              {
                label: $t('team.active_users'),
                value: memberCount,
              },
              {
                label: $t('team.available_licences'),
                value: auth.user?.subscription.features.max_users,
              },
            ]"
          />
        </NGi>
      </NGrid>
      <NCard v-else :bordered="false" content-style="padding: 24px;" class="rounded-xl">
        {{ $t('team.info_no_org') }}
      </NCard>
    </Container>

    <div v-if="auth.hasOrganization" class="mt-4">
      <CMessage v-if="!newMemberAvailable && auth.isOrganizationOwner" class="mb-4" type="warning">
        <div class="flex">
          {{ $t('team.max_limit') }}
          <NButton size="tiny" secondary class="ml-auto">
            {{ $t('team.manage') }}
          </NButton>
        </div>
      </CMessage>
      <NewUserFormDialog v-model="showAddMemberModal" />
      <TeamMembersTable
        :has-edit-permission="hasEditPermission"
        :new-member-available="newMemberAvailable"
        @update:show-add-member-modal="showAddMemberModal = $event"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import AppBarBreadcrumb from '@/components/common/AppBarBreadcrumb.vue'
import BigTextBanner from '@/components/BigTextBanner.vue'
import NewUserFormDialog from '@/views/team/components/NewUserFormModal.vue'
import StatsCard from '@/components/StatsCard.vue'
import TeamMembersTable from '@/views/team/components/TeamMembersTable.vue'

import { computed, ref, watch } from 'vue'
import { useAuthStore } from '@/store'
import { useOrganizationDetailQuery, useTeamMemberListQuery } from '@/api/vq/organization'

const auth = useAuthStore()
const showAddMemberModal = ref(false)

const fullMemberCount = ref<number>()

const { data: memberCount, isSuccess } = useTeamMemberListQuery<number>(
  {},
  {
    select: (d) => d.count,
  }
)

const hasEditPermission = computed(() => auth.hasPermission('team_mgmt'))
const newMemberAvailable = computed(() => {
  // if values does not exist return false
  if (!fullMemberCount.value || !auth.user?.subscription.features.max_users) return false

  return (
    auth.user.subscription.features.max_users === -1 ||
    fullMemberCount.value < auth.user?.subscription.features.max_users
  )
})

const { data: organization } = useOrganizationDetailQuery({
  // enable this query only if the user has an organization
  enabled: auth.hasOrganization,
})

watch(
  () => isSuccess.value,
  () => {
    if (isSuccess.value && !fullMemberCount.value) fullMemberCount.value = memberCount.value
  },
  { immediate: true }
)
</script>
